/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {Row, Button, Col, FormGroup, Input, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getColor } from "utils/token";
import { updatePasswordData } from "api/users";

export default function Security() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    oldPass: '',
    newPass: '',
    repeatPass: ''
  });

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updatePassword = async () => {
    setLoading(true);

    const { newPass, oldPass, repeatPass } = password;
    
    if(oldPass === "") {
      notifyAlert("danger", "Mensaje", "Contraseña actual vacía");
    }
    else if(newPass === '') {
      notifyAlert("danger", "Mensaje", "Contraseña nueva vacía");
    }
    else if(repeatPass === '') {
      notifyAlert("danger", "Mensaje", "Repetir contraseña vacía");
    }
    else if(oldPass === newPass) {
      notifyAlert("danger", "Mensaje", "La contraseña actual y la nueva son iguales");
    }
    else if(newPass !== repeatPass) {
      notifyAlert("danger", "Mensaje", "Las contraseñas no coinciden");
    }
    else {
      const jsn = {
        "oldPass": oldPass,
        "newPass": newPass
      }

      const res = await updatePasswordData(jsn);

      if(res.success === true) {
        setPassword({oldPass: '', newPass: '', repeatPass: ''});
        notifyAlert("success", "Mensaje", "Contraseña actualizada");
      }
      else {
        notifyAlert("danger", "Mensaje", "Eror al actualizar contraseña");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <div style={{
        padding: "10px"
      }}>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label">Contraseña actual</label>
              <Input
                value={password.oldPass}
                type="password"
                placeholder="Contraseña actual"
                onChange={val => setPassword({...password, oldPass: val.target.value})}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label">Contraseña nueva</label>
              <Input
                value={password.newPass}
                type="password"
                placeholder="Contraseña nueva"
                onChange={val => setPassword({...password, newPass: val.target.value})}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label">Repetir contraseña</label>
              <Input
                value={password.repeatPass}
                type="password"
                placeholder="Repetir contraseña"
                onChange={val => setPassword({...password, repeatPass: val.target.value})}
              />
            </FormGroup>
          </Col>
        </Row>
        {
          (loading) ? <div>
            <Spinner style={{
              color: '#004899',
            }} />
          </div> :
          <>
            <Button
              style={{
                backgroundColor: getColor(),
                borderColor: getColor(),
                color: '#ffffff',
                border: 0
              }}
              onClick={() => updatePassword()}
              > Guardar
            </Button>
          </>
        }
      </div>
    </>
  )
}