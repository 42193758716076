/* eslint-disable no-useless-escape */
import React, {useState, useContext, useEffect} from "react";
import {Spinner, Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AuthHeader from "components/Headers/AuthHeader";
import { AuthContext } from "../../context/AuthContext";
import { setLogin } from "../../api/auth";
import NotificationAlert from "react-notification-alert";
import OpenHeader from "components/Headers/OpenHeader";

function Login() {
  const notificationAlertRef = React.useRef(null);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const {signIn} = useContext(AuthContext);
  const [customize, setCustomize] = useState({
    logo: "",
    color: "",
    slogan: ""
  });

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    if (customizeData) {
      setCustomize({
        logo: customizeData.logo,
        color: customizeData.color,
        slogan: customizeData.slogan,
        id: customizeData.id
      })
    }}, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const validData = () =>{
    return (
      email !== "" &&
      pass !== ""
    )
  }

  const sendData = async () => {
    setLoading(true);
    
    if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false ) {
      notifyAlert('danger', "Mensaje", "El correo electrónico no es válido");
    }
    else {
      const res = await setLogin(email, pass);

      if(res.success === true) {
        signIn(res.token);
      }
      else {
        notifyAlert('danger', "Mensaje", res.message);
        setLoading(false);
      }
    }

    setLoading(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendData();
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <OpenHeader logo={customize.logo}/>

      <AuthHeader 
        color={customize.color}
        logo={customize.logo}
        slogan={customize.slogan}/>

      <Container>
        <Row className="justify-content-center">
          <Col lg="3" md="6">
            <h2 id="welcome-text" style={{
              fontSize: '35px',
              fontWeight: 'bold',
              textAlign: 'left',
            }}> Bienvenido </h2>
           
            <ul 
            id="initList"
            style={{
              padding: 0,
              lineHeight: 2,
              marginTop: '20px',
              listStyle: 'none'
            }}>
              <li> 
                <img alt='' 
                  style={{
                    width: "24px",
                    marginRight: "5px"
                  }} 
                  src={require('./../../assets/img/factura.png').default}/>
                Visualiza tus compras
              </li>
              <li> 
                <img alt='' 
                  style={{
                    width: "24px",
                    marginRight: "5px"
                  }} 
                  src={require('./../../assets/img/package.png').default}/>
                Gestiona tus paquetes
              </li>
              <li> 
                <img alt='' 
                  style={{
                    width: "24px",
                    marginRight: "5px"
                  }} 
                  src={require('./../../assets/img/notificacion.png').default}/>
                Recibe notificaciones
              </li>
              <li> 
                <img alt='' 
                  style={{
                    width: "24px",
                    marginRight: "5px"
                  }} 
                  src={require('./../../assets/img/lupa.png').default}/>
                Hazle seguimiento
              </li>
              <li> 
                <img alt='' 
                  style={{
                    width: "24px",
                    marginRight: "5px"
                  }} 
                  src={require('./../../assets/img/tarjeta-de-credito.png').default}/>
                Pagos en linea
              </li>
            </ul>
          </Col>
          <Col lg="5" md="7">
            <Card className="border-0 mb-0" style={{boxShadow: '0 0 0 0'}}>
              <CardBody className="px-lg-5 py-lg-5" id="cardBody">
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={email}
                        onChange={val => setEmail(val.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={pass}
                        onChange={val => setPass(val.target.value)}
                        onKeyDown={(event) => handleKeyDown(event)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {
                    (!loading) ? <>
                      <Button
                        disabled={!validData()}
                        type="button"
                        style={{
                          width: '100%', 
                          backgroundColor: customize.color, 
                          borderColor: customize.color,
                          color: '#fff'
                        }}
                        onClick={() => sendData()}
                      > Inicio de sesión
                      </Button>
                      </>
                      :
                      <>
                        <Spinner style={{
                          color: customize.color,
                        }} /> 
                      </>
                    }
                  </div>

                  <div style={{
                    marginTop: '15px',
                    float: 'left',
                    fontSize: '13px'
                  }}>
                    <Link 
                      to='/auth/register'>Registrate </Link>
                  </div>

                  <div style={{
                    marginTop: '15px',
                    float: 'right',
                    fontSize: '13px'
                  }}>
                    <Link 
                      to='/auth/password'>¿Olvidaste tu contraseña?</Link>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
