/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Bar } from "react-chartjs-2";
import { Spinner, Row, Button, Col } from "reactstrap";
import { getAllDatesData, getGraphData } from '../../../../api/graph';
import { getColor } from 'utils/token';
import NotificationAlert from "react-notification-alert";

export default function BarPackageByDate () {
  const notificationAlertRef = React.useRef(null);
  const arrMonth = [
    {
      "name": "Enero",
      "value": 1
    },
    {
      "name": "Febrero",
      "value": 2
    },
    {
      "name": "Marzo",
      "value": 3
    },
    {
      "name": "Abril",
      "value": 4
    },
    {
      "name": "Mayo",
      "value": 5
    },
    {
      "name": "Junio",
      "value": 6
    },
    {
      "name": "Julio",
      "value": 7
    },
    {
      "name": "Agosto",
      "value": 8
    },
    {
      "name": "Septiembre",
      "value": 9
    },
    {
      "name": "Octubre",
      "value": 10
    },
    {
      "name": "Noviembre",
      "value": 11
    },
    {
      "name": "Diciembre",
      "value": 12
    }
  ];
  const [loading, setLoading] = useState(false);
  const [arrDate, setArrDate] = useState([]);
  const [selYear, setSelYear] = useState(0);
  const [arrData, setArrData] = useState({
    legend: [],
    labels: [], 
    datasets: [{ 
      label: 'Total',
      backgroundColor: getColor(),
      data: []
    }]
  });

  useEffect(() => {
    funct();
  }, []);

  const funct = async () => {
    await getAllDates();
    await getArrInfo();
  }
  
  const getAllDates = async () => {
    const res = await getAllDatesData();
    console.log(res);
    setArrDate(res.data);
  }

  const getArrInfo = async () => {
    setLoading(true);

    let _year = new Date().getFullYear();

    if(selYear === 0) {
      _year = new Date().getFullYear();
    }
    else {
      _year = selYear;
    }

    const jsn = {
      year: _year,
      code: "TOTAL_YEAR"
    }

    const res = await getGraphData(jsn);

    const data = res.data;
    const valuesText = data.map( (v) => {
      return arrMonth[v.month-1].name;
    });

    const valuesData = data.map( (v) => {
      return v.total
    });

    setArrData({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Servicios',
        backgroundColor: getColor(),
        data: valuesData,
        type: 'bar'
      }]
    });

    setSelYear(_year);
    setLoading(false);
  }

  const sumTotal = () => {
    var total = 0;

    const dataToSum = arrData.datasets[0].data;

    for(var v of dataToSum) {
      total += v;
    }

    return total;
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      {
        (loading) ?
        <>
          <div className="text-center">
            <Spinner style={{color: '#004899'}} />
          </div>
        </> : 
        <>
          <div>
            <ul style={{
              padding: '0px',
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <li>
                <h3>Total de paquete por fecha</h3>
              </li>
              <li style={{
                display: 'flex',
              }}>
                <select
                  className="form-control" 
                  style={{
                    marginLeft: '5px',
                    appearance: 'auto'
                  }}
                  value={selYear}
                  onChange={val => setSelYear(val.target.value)}>
                  <option value=""> Año </option>
                  {
                    arrDate.map( (d, i) => ( 
                      <option key={i} value={d.dates}> {d.dates}</option>
                    ))
                  }
                </select>
                <Button
                  size='sm'
                  style={{
                    backgroundColor: '#004899',
                    color: '#fff',
                    border: 0,
                    marginLeft: '5px'
                  }}
                  onClick={() => getArrInfo()}
                  > Actualizar
                </Button>
              </li>
            </ul>
          </div>
          <Row>
            <Col md="9">
              <Bar
                data={arrData}
                options= {{maintainAspectRatio: false}}
              />
            </Col>
            <Col md="3">
              <ul style={{
                padding: '0px',
                listStyle: 'none'
              }}>
                {
                  arrData.legend.map( (v, i) => (
                    <li key={i}> 
                      <b>{arrMonth[v.month-1].name}:</b> {v.total}
                    </li>
                  ))
                }
                <hr />
                <li>
                  <b>Total:</b> {sumTotal()}
                </li>
              </ul>
            </Col>
          </Row>
        </>
      }
    </>
  )
}

