/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getColor } from 'utils/token';
import NotificationAlert from "react-notification-alert";
import { processNextStatusData } from "../../../api/admin";

export default function FindOrder() {
  const notificationAlertRef = React.useRef(null);
  const [search, setSearch] = useState("");

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const validationToProcess = async () => {
    const _search = String(search).split("-");

    console.log(_search);

    if(_search.length === 2){
      const tracking = _search[1];
      console.log(tracking);

      const res = await processNextStatusData(tracking);

      if(res.success){
        setSearch("");
        notifyAlert("success", "Éxito", "Se ha procesado el paquete correctamente.");
      }
      else {
        notifyAlert("danger", "Error", "No se ha podido procesar el paquete.");
      }
    }
    else {
      window.location.href = `/admin/process/${search}`;
    }

  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      window.location.href = `/admin/process/${search}`;
    }
  }

  const searchOrder = () => {
    validationToProcess();
  }
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Procesar paquetes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Buscar por tracking de UPS o ID del paquete
                        </label>
                        <Input
                          autoFocus={true}
                          value={search}
                          className="form-control"
                          onKeyDown={(e) => {handleEnterKey(e)}}
                          onChange={(val) => setSearch(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button 
                          style={{
                            backgroundColor: getColor(),
                            border: getColor(),
                            color: "white"
                          }}
                          onClick={() => searchOrder()}
                        > Buscar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>
    </>
  )
}
