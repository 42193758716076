/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getUsersByIdData, updateUsersData, setNewPasswordData, deleteUsersData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

export default function EditUsers() {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState('0');
  const [role, setRole] = useState('');
  const [modal, setModal] = useState(false);
  const [modalPass, setModalPass] = useState(false);
  const [loading, setLoading] = useState({page: true, btn: false});
  const [error, setError] = useState(false);

  useEffect( ()=> {
    getUsersById();
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getUsersById = async () => {
    const res = await getUsersByIdData(id);

    if(res.success === true) {
      setError(false);
      setName(res.data.name);
      setEmail(res.data.email);
      setActive(res.data.active);
      setRole(res.data.role);
    }
    else {
      notifyAlert('danger', 'Error', "Ha ocurrido un error al obtener los datos del usuario");
    }
    setLoading(false);
  }

  const updateUser = async () => {
    setLoading(true);

    if(String(name).trim() === "") {
      notifyAlert('danger', 'Error', "El nombre no puede estar vacio");
    }
    else if(String(email).trim() === "") {
      notifyAlert('danger', 'Error', "El correo no puede estar vacio");
    }
    else if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email)) {
      notifyAlert('danger', 'Error', "El correo no es valido");
    }
    else {
      const jsn = {
        id: id,
        name: name,
        email: email,
        active: active
      }
      
      const res = await updateUsersData(jsn);

      if(res.success === true) {
        notifyAlert('success', 'Mensaje', "Usuario actualizado correctamente");
      }
      else {
        notifyAlert('danger', 'Error', "Ha ocurrido un error al actualizar el usuario");
      }
    }

    setLoading(false);
  }

  const setNewPassword = async () => {
    const res = await setNewPasswordData(id);

    if(res.success === true) {
      notifyAlert('success', 'Mensaje', "Se ha enviado un correo con la nueva contraseña");
    }
    else {
      notifyAlert('danger', 'Error', "Ha ocurrido un error al enviar el correo");
    }
  }

  const deleteUsers = async () =>  {
    const res = await deleteUsersData(id);

    if(res.success === true) {
      window.location.href = '/admin/users'
    }
    else {
      notifyAlert('danger', 'Error', "Ha ocurrido un error al eliminar el usuario");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Usuarios </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
              {
                (loading.page) ?
                  <div className="text-center">
                    <Spinner style={{color: '#004899'}} />
                  </div>:
                (error) ? 
                  <div>
                    <div colSpan={10} className='text-muted text-center'>
                      <label>Ha ocurrido un error, por favor intente nuevamente. </label>
                      <br />
                      <Button color="gray" to="/admin/brand" tag={Link}> Regresar </Button>
                    </div>
                  </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> E-mail </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Rol </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={role}
                          onChange={val => setRole(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          <option value="1"> Gerente </option>
                          <option value="2"> Operador </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Restaurar clave </label>
                        <br />
                        <Button
                          style={{
                            backgroundColor: '#004899',
                            color: '#fff',
                            border: 0
                          }}
                          onClick={() => setModalPass(!modalPass)}
                          > Restaurar contraseña
                        </Button>
                        <p> 
                          <small> Se enviara un correo al usuario con la nueva contraseña </small>
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/users" tag={Link}> Regresar </Button>
                            <Button color="danger" onClick={() => setModal(!modal)}> Eliminar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateUser()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
 
          </Col>
        </Row>
	
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar este usuario?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteUsers()}> Eliminar </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalPass} toggle={() => setModalPass(!modalPass)}>
          <ModalHeader toggle={() => setModalPass(!modalPass)}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea actualizar la contraseña?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalPass(!modalPass)}> Cancelar </Button>
            <Button color="warning" onClick={() => setNewPassword()}> Actualizar </Button>
          </ModalFooter>
        </Modal>
     
     
      </Container>
    </>
  )
}