/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import { Spinner, Col, Card, CardHeader, Table, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText, CardBody } from "reactstrap";
import { getClientsData } from '../../../api/admin';
import { getColor } from "utils/token";

const Clients = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getClients();
  }, []);


  const getClients = async () => {
    setLoading(true);
    const res = await getClientsData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Clientes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      placeholder="Buscar"
                      onChange={(val) =>  setSearch(val.target.value)}
                    />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Imagen </th>
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Email </th>
                      <th scope="col"> Telefono </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente. </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getClients()}> Cargar </Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {
                              (d.picture === null) ? <>
                                <div 
                                  className="avatar avatar-sm"
                                />
                              </> : <>
                                <img
                                  alt="..."
                                  className="avatar avatar-sm"
                                  src={d.picture}
                                />
                              </>
                            }
                          </td>
                          <td>
                            {String(d.name).trim()}
                          </td>
                          <td>
                            {String(d.email).trim()}
                          </td>
                          <td>
                            {
                              (d.phone === null) ? <>
                                <label className='text-muted'> No registrado </label>
                              </> : <>
                                {String(d.phone).trim()}
                              </>
                            }
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{backgroundColor: getColor(), border:0, color: "#ffffff"}} 
                              to={`/admin/client/${d.id}`} 
                              tag={Link}
                            > Detalle </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Clients;