/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Container, Row, Col, Button } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getDataByIdentifierData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

export default function ViewDomesticOrder () {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState({ page: false, payBtn: false });
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    "id": "",
    "status": "",
    "status_id": "",
    "product_name": "",
    "package_number": "",
    "tracking_number": "",
    "price": "",
    "picture": "",
    "payment": "",
    "sender_name": "",
    "sender_email": "",
    "sender_phone": "",
    "sender_address": "",
    "sender_place": "",
    "receive_name": "",
    "receive_email": "",
    "receive_phone": "",
    "receive_address": "",
    "weight": "",
    "height": "",
    "width": "",
    "length": "",
    "content": "",
    "coupon": "",
    "discount": "",
    "final_payment": "",
    "ups_tracking": "",
    "date": "",
    "product_type": "",
    'air_eta': "",
    'ocean_eta': "",
    'process_date': "",
    'lot_tracking': "",
    'payment_warning': "",
    'locker': "",
    "ups_pickup_cost": ""
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataByIdentifier();
  }, []);
  

  const getDataByIdentifier = async () => {
    setLoading({...loading, page: true});
    const res = await getDataByIdentifierData(id);

    if(res.success === true) {
      setError(false);
      setData({
        "id": res.data.id,
        "status": res.data.status,
        "product_name": res.data.product_name,
        "package_number": res.data.package_number,
        "tracking_number": res.data.tracking_number,
        "price": res.data.price,
        "picture": res.data.picture,
        "payment": res.data.payment,
        "sender_name": res.data.sender_name,
        "sender_email": res.data.sender_email,
        "sender_phone": res.data.sender_phone,
        "sender_address": res.data.sender_address,
        "sender_place": res.data.sender_place,
        "receive_name": res.data.receive_name,
        "receive_email": res.data.receive_email,
        "receive_phone": res.data.receive_phone,
        "receive_address": res.data.receive_address,
        "weight": res.data.weight,
        "height": res.data.height,
        "width": res.data.width,
        "length": res.data.length,
        "content": res.data.content,
        "ups_tracking": res.data.ups_tracking,
        "date": dayjs(res.data.date).add(1, 'day').format("DD/MM/YYYY"),
        "product_type": res.data.product_type,
        'ups_prn': res.data.ups_prn,
        'payment_warning': res.data.payment_warning,
        'locker': res.data.locker,
        'final_payment': res.data.final_payment,
        "ups_pickup_cost": res.data.ups_pickup_cost,
      });
    }
    else {
      setError(true);
      setData({});
    }
    setLoading({...loading, page: false});
  }

  const getWeightKg = (value) => {
    return (value * 0.45359237).toFixed(2);
  }

  const sumPayment = (price, ups, final) => {
    const _price = (price === null) ? 0 : Number(price);
    const _ups = (ups === null) ? 0 : Number(ups);
    const _final = (final === null) ? 0 : Number(final);

    return (_price + _ups + _final).toFixed(2);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card 
              style={{
                border:  (data.payment_warning === 1) ? "3px solid #f5365c" : ""
              }}
            >
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> VTS Tracking: #{String(data.tracking_number).toUpperCase()} </h3>
                    <h3> UPS Tracking: {String(data.ups_tracking).toUpperCase()} </h3>
                  </div>
                  <div 
                    style={{
                      textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                    className="col mb-0 float-rigth">
                      {
                        (data.payment_warning === 1) ? <> 
                          <div 
                            style={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "right",
                              justifyContent: "flex-end"
                            }}
                            className="col mb-0 float-rigth">
                              <span style={{
                                color: "#f5365c",
                              }}>
                                Este paquete ha sido recibido, pendiente por pago 
                              </span>
                            <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                          </div>
                        </> : <></>
                      }
                      <a 
                        className='btn btn-sm'
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          display: "block",
                          backgroundColor: getColor(),
                          borderColor: getColor()
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://es-us.ups.com/track?loc=es_US&&tracknum=${data.ups_tracking}`}>
                        Rastrear en UPS
                      </a>
                  </div>
                </Row>
              </CardHeader>
                
              <CardBody>
                <div className='paddingHorizontal'>
                  {
                    (loading.page === true) ? <>
                      <div className="text-center">
                        <Spinner 
                          style={{
                            color: getColor
                          }}
                        />
                      </div>
                    </> : <>
                      {
                        (error === true) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>
                          <Row>
                            <Col style={{textAlign: "center"}}>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={data.picture} />
                            </Col>

                            <Col>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                              }}>
                                <li>
                                  <h2> {data.product_name} </h2>
                                </li>
  
                                <li>
                                  {
                                    (data.final_payment !== null) ? <>
                                    <li>
                                      <label> <b>💵 Pago total: </b> ${ sumPayment(data.price, data.ups_pickup_cost, data.final_payment) }</label>
                                    </li>
                                    </> : <></>
                                  }
                                  <label> 
                                    <b>
                                      {
                                        (data.final_payment === null) ? "💵 Pago: " : "💵 Primer pago: "
                                      }
                                    </b>
                                    ${Number(data.price).toFixed(2)}
                                  </label>
                                </li>
                                {
                                  (data.final_payment !== null) ? <>
                                    <li>
                                      <label> 
                                        <b style={{
                                          color: (data.payment === 1) ? "#f5365c" : "#525f7f"
                                        }}>
                                          {
                                            (data.payment === 1) ? "💵 Pago pendiente: " : "💵 Segundo pago: "
                                          } 
                                        </b>
                                        ${Number(data.final_payment).toFixed(2)}
                                      </label>
                                    </li>
                                  </> : <></>
                                }
                                {
                                  (data.ups_pickup_cost !== null) ? <> 
                                    <li>
                                      <label> 
                                        <b>
                                          💵 UPS Pickup: 
                                        </b>
                                        ${Number(data.ups_pickup_cost).toFixed(2)}
                                      </label>
                                    </li>
                                  </>: <></>
                                  }
                                <li>
                                  <label> <b>🚚 Pickup: </b> 
                                    {
                                      (data.ups_prn === null) ? "No" : `Si (${data.ups_prn})`
                                    }
                                  </label>
                                </li>

                                <li>
                                  <label> <b>📅 Compra: </b> {data.date} </label>
                                </li>

                                <li>
                                  <label> <b>#️⃣ Locker: </b> {(data.locker)} </label>
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="4">
                              <h3> 📤 Remitente </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.sender_name}
                                </li>
                                <li> 
                                  📱 {data.sender_phone}
                                </li>
                                <li> 
                                  ✉ {data.sender_email}
                                </li>
                                <li> 
                                  <b>Direccion: </b> {data.sender_address}
                                </li>
                                <li> 
                                  {data.sender_place}
                                </li>
                              </ul>
                            </Col>
                            
                            <Col lg="4">
                              <h3> 📥 Consignatario </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.receive_name}
                                </li>
                                <li> 
                                  📱 {data.receive_phone}
                                </li>
                                <li> 
                                  ✉ {data.receive_email}
                                </li>
                                <li> 
                                <b>Direccion: </b> {data.receive_address}
                                </li>
                              </ul>
                            </Col>

                            <Col lg="4">
                              <h3> 📦 Datos del Paquete </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  <b> Servicio: </b> Domestico
                                </li>
                                {
                                  (data.weight !== null) ? <>
                                    <li> 
                                      <b> Dimensiones: </b> {data.height} x {data.width} x {data.length} pulgadas
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <b> Peso: </b> {data.weight}lbs - {getWeightKg(data.weight)}kg
                                </li>
                                <li> 
                                  <b> Contenido: </b> {data.content}
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                <Button color="gray" to="/admin/orders/domestic" tag={Link}> Regresar </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
