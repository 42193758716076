/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Row, Button, Col, FormGroup, Input, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Compressor from 'compressorjs';
import lang from 'lang';
import { setToken, getColor } from "utils/token";
import { getUserInfoData, updateInfoData, setPictureInfo } from "api/users";
const noPicture = require('./../../../../assets/img/noPicture.png').default;

export default function Info() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ load: false, email: false, pass: false});
  const [user, setUser] = useState({ name:'', 
    phone:'', 
    email:'', 
    picture: null
  });
  const [picture, setPicture] = useState('');
  const [file, setFile] = useState({});

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    getUserInfo();
  },[]);
  
  const getUserInfo = async () => {
    const res = await getUserInfoData();
    
    setUser({
      name: res.data.name,
      email: res.data.email,
      phone: res.data.phone
    });

    setPicture(res.data.picture);
    setLoading({...loading, load: false});
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

  const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const savePicture = async () => {
    setLoading({...loading, picture: true});
    let fileCompress = null;
    
    if(file && Object.keys(file).length === 0 && file.constructor === Object) {
      notifyAlert("danger", "Mensaje", "No se ha seleccionado ninguna imagen");
    }
    else {
      fileCompress = await renderImageToSend(file);
      
      const res = await setPictureInfo(fileCompress);

      if(res.success === true) { 
        setToken('vboxToken', res.data);
        window.location.reload();
      }
      else {
        notifyAlert("danger", "", res.error.message);
      }
    }
    setLoading({...loading, picture: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});
    const name = String(user.name).trim();
    const email = String(user.email).trim();
    const phone = String(user.phone).trim();

    if(name === "") {
      notifyAlert("danger", "Mensaje", "El nombre no puede estar vacio");
    }
    else if(email === "") {
      notifyAlert("danger", "Mensaje", "El correo no puede estar vacio");
    }
    else if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false ) {
      notifyAlert("danger", "Mensaje", "El correo no es valido"); 
    }
    else if(phone === "") {
      notifyAlert("danger", "Mensaje", "El telefono no puede estar vacio");
    }
    else {
      const jsn = {
        'name': name,
        'email': email,
        'phone': phone,
      }

      const res = await updateInfoData(jsn);

      if(res.success === true) {
        setToken('vboxToken', res.data);
        window.location.reload();
      }
      else {
        notifyAlert("danger", "Mensaje", res.message);
      }
    }

    setLoading({...loading, save: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div style={{
        padding: "10px"
      }}>
        <div className='paddingHorizontal'>
        {
          (loading.load) ? <div className="text-center">
            <Spinner style={{
              color: getColor(1)
            }} />
          </div> :
          <>
            <Row>
              <Col lg="6">
                <div className="text-center">
                  {
                    (picture === null) ? <>
                    <img
                      alt="..."
                      style={{
                        width: '200px',
                        height: '200px',
                        borderRadius: '50%'
                      }}
                      id="imgBrand"
                      src={noPicture}/>
                    </> : <>
                      <img
                      alt="..."
                      style={{
                        width: '200px',
                        height: '200px',
                        borderRadius: '50%'
                      }}
                      id="imgBrand"
                      src={picture}/>
                    </>
                  }
                  <br />
                  {
                    (loading.picture) ? 
                    <div className="text-center" style={{marginTop: '5px'}}>
                      <Spinner style={{
                        color: getColor()
                      }} />
                    </div> :
                    <> 
                      <label className="custom-file-upload">
                        <input 
                          type="file"
                          accept="image/*"
                          onChange={onImageChange}/>
                        {lang("EDIT")}
                      </label>
                      <Button
                        style={{
                          backgroundColor: '#6c757d',
                          color: '#fff',
                          border: 0
                        }}
                        onClick={() => savePicture()}> {lang("SAVE")}
                      </Button>
                    </> 
                  }
                </div>
              </Col>
              
              <Col lg="6">
                <div>
                  <FormGroup>
                    <label className="form-control-label"> Nombre </label>
                    <Input
                      placeholder='Nombre'
                      value={user.name}
                      className="form-control"
                      onChange={val => setUser({...user, name: val.target.value })}/>
                  </FormGroup>

                  <FormGroup>
                    <label className="form-control-label"> E-mail </label>
                    <Input
                      placeholder='E-mail'
                      value={user.email}
                      className="form-control"
                      onChange={val => setUser({...user, email: val.target.value })}/>
                  </FormGroup>
                  
                  <FormGroup>
                    <label className="form-control-label"> Telefono </label>
                    <Input
                      placeholder='Telefono'
                      value={user.phone}
                      className="form-control"
                      onChange={val => setUser({...user, phone: val.target.value })}/>
                  </FormGroup>

                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12" >
                <br />
                <div className="float-right">
                {
                  (loading.save) ? <div className="text-center">
                    <Spinner style={{
                      color: getColor()
                    }} />
                  </div> :
                  <>
                    <Button
                      style={{
                        backgroundColor: getColor(),
                        color: "#fff",
                        border: 0
                      }}
                      onClick={() => saveData()}
                      > Guardar
                    </Button>
                  </>
                }
                </div>
              </Col>
            </Row>
          </>
        }
        </div>
      </div>
    </>
  )
}