/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import OpenHeader from 'components/Headers/OpenHeader';

export default function OpenTracking () {
  const notificationAlertRef = React.useRef(null);
  const [search, setSearch] = useState('');
  const [customize, setCustomize] = useState({
    logo: "",
    color: "",
    slogan: ""
  });

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    if (customizeData) {
      setCustomize({
        logo: customizeData.logo,
        color: customizeData.color,
        slogan: customizeData.slogan,
        id: customizeData.id
      })
    }
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const searchTracking = () => {
    const value = String(search).trim().trimEnd().trimStart();

    if(value === "") {
      notifyAlert("danger", "Error", "El campo de búsqueda no puede estar vacío");
    }
    else {
      window.location.href = `/tracking/${value}`;
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchTracking();
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Container fluid>
        <OpenHeader />

        <Row>
          <Col lg="12">
            <div className="text-center">
              <img alt="..." src={customize.logo} style={{ width: '150px' }} />
              <h3> {customize.slogan} </h3>
            </div>
          </Col>

          <Col lg="12">
            <div className="text-center">
              <h1 style={{
                color: customize.color,
                fontSize: '2.2rem'
              }}> Rastrea tu paquete </h1>
            </div>
          </Col>

          <Col lg="12">
            <div className="text-center">
              <p> Aqui puedes introducir tu numero de tracking </p>

              <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-box-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input 
                  placeholder="Buscar" 
                  type="text" 
                  value={search} 
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => setSearch(e.target.value)} />
              </InputGroup>

                <Button
                  style={{
                    backgroundColor: customize.color,
                    color: '#fff',
                    width: "60%"
                  }}
                  onClick={() => searchTracking()}
                >
                  Buscar
                </Button>
            </div>
            
            
          </Col>

        </Row>

      </Container>
    </>
  )
}
