/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import { getColor, getTokenData, getAgencyData } from 'utils/token';
import { setSupportData, getTicketByIdD, getTicketByIdDetailData } from "../../api/users";
import dayjs from 'dayjs';

export default function OpenSupport() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: true, table: false });
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});

  useEffect( async () => {
    await getTicketById();
    await getTicketByIdDetail();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getTicketById = async () => {
    setLoading({ ...loading, page: true });

    const res = await getTicketByIdD(id);

    if(res.success === true) {
      setInfo(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({ ...loading, page: false });
  }

  const getTicketByIdDetail = async () => {
    setLoading({ ...loading, table: true });

    const res = await getTicketByIdDetailData(id);

    if(res.success === true) {
      setData(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({ ...loading, table: false });
  }

  const createTicket = async () => {
    setLoading(true);
    
    if(String(message).trim() === "") {
      notifyAlert("danger", "Error", "El mensaje no puede estar vacío");
    }
    else {
      const jsn = {
        "type": "awnser",
        "id": id,
        "message": message
      }
      const res = await setSupportData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Mensaje", "El ticket se ha creado correctamente, en breve sera atendido");
        setMessage('');
        getTicketByIdDetail();
      }
      else {
        notifyAlert("danger", "Mensaje", "Ha ocurrido un error al crear el ticket");
      }
    }
    setLoading(false);
  }

  const typeUser = (val, k) => {
    console.log(val, k);
    const user_name = getTokenData('vboxToken', 'name');
    const company_name = getAgencyData('name');

    if(val === null) {
      return `${user_name}`;
    }
    else {
      return `Equipo de ${company_name}`
    }

  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Atención al Cliente  </h3>
                  </div>
                  <div className="col mb-0 float-right">
                    <div style={{
                      textAlign: 'right'
                    }}>
                      <Button 
                        size='sm'
                        color="gray" 
                        to="/admin/support"
                        tag={Link}> Regresar </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (info.status === "OPEN") ? <>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> Mensaje </label>
                            <textarea
                              value={message}
                              className="form-control"
                              onChange={val => setMessage(val.target.value)}>
                            </textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>: <></>
                  }

                  {
                    (info.status === "OPEN") ? <> 
                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                              <div>
                                <Button
                                  style={{
                                    backgroundColor: getColor(),
                                    color: '#fff',
                                    border: 0
                                  }}
                                  onClick={() => createTicket()}
                                  > Guardar
                                </Button>
                              </div>
                            } 
                          </div>
                        </Col>
                      </Row>
                    </> : <></>
                  }

                  <Row>
                    <Col>
                      <ul style= {{
                        listStyle: 'none',
                        padding: 0
                      }}>
                        <li> <b> ID: </b> {String(info.identifier).toLocaleUpperCase()} </li>
                        <li> <b> Asunto: </b> {info.subject} </li>
                        <li> <b> Estado: </b> {info.status} </li>
                        <li> <b> Fecha: </b> {dayjs(info.date).format("MM/DD/YYYY")} </li>
                      </ul>
                      <label><b>Mensajes </b></label>
                      <ul style={{
                        listStyle: 'none',
                        padding: 0
                      }}>
                        {
                          (loading.table) ? <>
                            <li> <Spinner style={{color: getColor()}}/> </li>
                          </> : 
                          <>
                            {
                              (data.length === 0) ? <li> No hay datos </li> :
                              <>
                                {
                                  (data.map((item, key) => {
                                    return (
                                      <li key={key}
                                        style = {{
                                          borderBottom: (key === data.length-1) ? '0' : '1px solid #ccc',
                                          padding: '10px 0'
                                        }}
                                      >
                                        <b> {typeUser(item.type, data.length)} </b> 
                                        <br />
                                        {item.message}
                                      </li>
                                    )
                                  }))
                                }
                              </>
                            }
                          </>
                        }
                      </ul>
                    </Col>
                  </Row>

                </div>
              </CardBody>
            </Card>
          </div>
        </Row>


        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar la ciudad?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}
