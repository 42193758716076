import React from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";

export default function OpenHeader({logo}) {
  const [collapse, setCollapse] = React.useState(false);
    
  return (
    <>
      <div style={{
        backgroundColor: 'transparent',
      }}>
        <Navbar color="faded" light>
          <NavbarBrand className="me-auto" href="/">
            {
              (!logo) ? (
                <img alt="..." width={42} src={require('./../../assets/img/logo.png').default} />
              ) : (
                <img alt="..." width={42} src={logo} />
              )
            }
          </NavbarBrand>

          <NavbarToggler
            className="me-2"
            style={{
              border: '0',
              fontSize: '15px'
            }}
            onClick={() => setCollapse(!collapse)}
          />

          <Collapse navbar isOpen={collapse}>
            <Nav navbar>
              <NavItem>
                <NavLink href="/tracking"> Rastrea tu paquete </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink href="/calculator"> Calculadora </NavLink>
              </NavItem>
              {/* 
              <NavItem>
                <NavLink href="/destination"> Destinos </NavLink>
              </NavItem> 
              */}

              <NavItem>
                <NavLink href="/"> Inicio de sesión </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  )
}
