import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getStateData, setNewCityData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function NewCity() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [arrState, setArrState] = useState([]);
  const [state, setState] = useState('');
  const [name, setName] = useState('');
  const [cost, setCost] = useState({air: 1, sea: 1});
  const [eta, setETA] = useState({air: 1, sea: 1});
  const [delivery, setDelivery] = useState({air: 1, sea: 1});
  const [active, setActive] = useState('0');
  
  useEffect(() => {
    const getStates = async () => {
      const res = await getStateData();
      if(res.success === true) {
        setArrState(res.data);
      }
    }
    getStates();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const addNewData = async () => {
    setLoading(true);
    
    if(String(state).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el estado");
    }
    else if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre de la ciudad");
    }
    else if(String(cost.sea).trim() === "" || Number(cost.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo de envío por mar");
    }
    else if(String(eta.sea).trim() === "" || Number(eta.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el ETA de envío por mar");
    }
    else if(String(delivery.sea).trim() === "" || Number(delivery.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo del delivery por mar");
    }
    else if(String(cost.air).trim() === "" || Number(cost.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo de envío por aire");
    }
    else if(String(eta.air).trim() === "" || Number(eta.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el ETA de envío por aire");
    }
    else if(String(delivery.air).trim() === "" || Number(delivery.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo del delivery por aire");
    }
    else {
      const jsn = {
        "name": name,
        "state": state,
        "cost": cost,
        "eta": eta,
        "delivery": delivery,
        "active": active,
      }

      const res = await setNewCityData(jsn);

      if(res.success === true) {
        setName("");
        setState("");
        setCost({air: 1, sea: 1});
        setETA({air: 1, sea: 1});
        setDelivery({air: 1, sea: 1});
        setActive('0');
        notifyAlert("success", "Éxito", "La ciudad se ha creado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al crear la ciudad");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Ciudad </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Estado</label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={state}
                          onChange={val => setState(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          {
                            arrState.map((item, key) => {
                              return (
                                <option value={item.id} key={key}> {item.state} </option>
                              )
                            })
                          }
                        </select>
                      </FormGroup>
                    </Col>
                          
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Costo Maritimo </label>
                        <Input
                          type='number'
                          min={1}
                          pattern='[0-9]+([,\.][0-9]+)?'
                          value={cost.sea}
                          className="form-control"
                          onChange={val => setCost({...cost, sea: val.target.value})}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> ETA Maritimo </label>
                        <Input
                          value={eta.sea}
                          className="form-control"
                          onChange={val => setETA({...eta, sea: val.target.value})}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Costo del Delivery Maritimo </label>
                        <Input
                          value={delivery.sea}
                          className="form-control"
                          onChange={val => setDelivery({...delivery, sea: val.target.value})}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Costo Aereo </label>
                        <Input
                          value={cost.air}
                          className="form-control"
                          onChange={val => setCost({...cost, air: val.target.value})}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> ETA Aereo </label>
                        <Input
                          value={eta.air}
                          className="form-control"
                          onChange={val => setETA({...eta, air: val.target.value})}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Costo del Delivery Aereo </label>
                        <Input
                          value={delivery.air}
                          className="form-control"
                          onChange={val => setDelivery({...delivery, air: val.target.value})}/>
                      </FormGroup>
                    </Col>


                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: getColor()}}/> :
                          <div>
                            <Button color="gray" to="/admin/address/city" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewData()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}
