/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Header from "../../components/Headers/Header";
import { Card, CardHeader, CardBody, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";
import NotificationAlert from "react-notification-alert";

export default function Tracking () {
  const notificationAlertRef = React.useRef(null);
  const [search, setSearch] = useState('');
  const [customize, setCustomize] = useState({
    logo: "",
    color: "",
    slogan: ""
  });

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    if (customizeData) {
      setCustomize({
        logo: customizeData.logo,
        color: customizeData.color,
        slogan: customizeData.slogan,
        id: customizeData.id
      })
    }
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const searchTracking = () => {
    const value = String(search).trim().trimEnd().trimStart();

    if(value === "") {
      notifyAlert("danger", "Error", "El campo de búsqueda no puede estar vacío");
    }
    else {
      window.location.href = `/admin/tracking/${value}`;
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchTracking();
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Rastrea tu paquete </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="text-center">
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Buscar" 
                      type="text" 
                      value={search} 
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) => setSearch(e.target.value)} />
                    <Button
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        backgroundColor: customize.color,
                        color: '#fff'
                      }}
                      onClick={() => searchTracking()}
                    >
                      Buscar
                    </Button>
                  </InputGroup>
                </div>

                <div>
                  <p> Aqui puedes introducir tu numero de tracking </p>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
