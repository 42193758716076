/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';
import { setSupportData } from "../../api/users";

export default function OpenSupport() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: true, btn: false });
  const [subject, setSubject] = useState('');
  const [tracking, setTracking] = useState('');
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const createTicket = async () => {
    setLoading(true);
    
    if(String(subject).trim() === "") {
      notifyAlert("danger", "Error", "El asunto no puede estar vacío");
    }
    else if(String(message).trim() === "") {
      notifyAlert("danger", "Error", "El mensaje no puede estar vacío");
    }
    else {
      const jsn = {
        "subject": subject,
        "tracking": tracking,
        "message": message
      }
      const res = await setSupportData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Mensaje", "El ticket se ha creado correctamente, en breve sera atendido");
        setSubject('');
        setTracking('');
        setMessage('');
      }
      else {
        notifyAlert("danger", "Mensaje", "Ha ocurrido un error al crear el ticket");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Atención al Cliente  </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Asunto </label>
                        <Input
                          value={subject}
                          className="form-control"
                          onChange={val => setSubject(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Tracking number </label>
                        <Input
                          value={tracking}
                          className="form-control"
                          onChange={val => setTracking(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Mensaje </label>
                        <textarea
                          value={message}
                          className="form-control"
                          onChange={val => setMessage(val.target.value)}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                          <div>
                            <Button color="gray" to="/admin/support" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => createTicket()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar la ciudad?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}
