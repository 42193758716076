import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Card, Container, Row, Button} from "reactstrap";
import Header from "components/Headers/Header";
import { getTokenData } from "./../../utils/token";

export default function Welcome() {
  const [agency, setAgency] = useState({});

  useEffect(() => {
    welcomeData();
  }, []);
  
  const welcomeData = async () => {
    const userData = await getTokenData("vboxToken", "a");
    const customizeData = JSON.parse(localStorage.getItem("agency"));

    setAgency({
      name: customizeData.name,
      logo: customizeData.logo,
      color: customizeData.color,
      slogan: customizeData.slogan,
      id: customizeData.id,
      userType: userData
    });
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <div style={{
                textAlign: 'center'
              }}>
                <img alt="...." src={agency.logo} 
                style={{
                  marginTop: '30px',
                  width: '200px',
                  opacity: '0.9'
                }}/>
                <br /> <br />
                <label style={{
                  fontSize: '30px',
                  fontWeight: '400',
                  marginBottom: '10px'
                }}> Bienvenido a <b>{agency.name}</b> </label>
                <br />
                <p style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  color: agency.color
                }}> {agency.slogan} </p>
                <h2> ¿Cuanto tengo que pagar?</h2>
                <Link to={agency.userType === 1 ? "/admin/calculate" : "/admin/calculator"}>
                  <Button 
                    style={{
                      backgroundColor: agency.color,
                      borderColor: agency.color,
                      color: '#fff'
                    }}
                    color="primary"> Calcular o Cotizar tu Envío </Button>
                </Link>
                <br />
                <br />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
