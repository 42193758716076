import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllDatesData() {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.graph}/dates`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGraphData(jsn) {
	const token = getToken('vboxToken');
	
	try {
		const res = await axios.post(CONS.graph , jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

