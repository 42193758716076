/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getColor } from 'utils/token';

export default function SearchOrder() {
  const [search, setSearch] = useState("");

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      window.location.href = `/admin/search/${search}`;
    }
  }

  const searchOrder = () => {
    window.location.href = `/admin/search/${search}`;
  }
  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Rastrear Paquete </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Ingrese tracking de UPS o ID del paquete
                        </label>
                        <Input
                          autoFocus={true}
                          value={search}
                          className="form-control"
                          onKeyDown={(e) => {handleEnterKey(e)}}
                          onChange={(val) => setSearch(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button 
                          style={{
                            backgroundColor: getColor(),
                            border: getColor(),
                            color: "white"
                          }}
                          onClick={() => searchOrder()}
                        > Buscar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>
    </>
  )
}
