/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Container, Row, Col, Button } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getDataByTrackingData, payPendingDebtData, downloadInvoiceData } from "../../api/purchase";
import NotificationAlert from "react-notification-alert";

export default function ViewTracking () {
  const { track } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: false, payBtn: false, invoiceBtn: false, status: false });
  const [status, setStatus] = useState(1);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    "id": "",
    "status": "",
    "status_id": "",
    "product_name": "",
    "package_number": "",
    "tracking_number": "",
    "price": "",
    "picture": "",
    "payment": "",
    "sender_name": "",
    "sender_email": "",
    "sender_phone": "",
    "sender_address": "",
    "sender_place": "",
    "receive_name": "",
    "receive_email": "",
    "receive_phone": "",
    "receive_address": "",
    "weight": "",
    "height": "",
    "width": "",
    "length": "",
    "content": "",
    "coupon": "",
    "discount": "",
    "final_payment": "",
    "ups_tracking": "",
    "date": "",
    "product_type": "",
    "air_eta": "",
    "ocean_eta": "",
    "ups_pickup_cost": ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataByTracking();
  }, []);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getDataByTracking = async () => {
    setLoading({...loading, page: true});
    const res = await getDataByTrackingData(track);

    if(res.success === true) {
      setError(false);
      setStatus(res.data.status_id);
      setData({
        "id": res.data.id,
        "status": res.data.status,
        "product_name": res.data.product_name,
        "package_number": res.data.package_number,
        "tracking_number": res.data.tracking_number,
        "price": res.data.price,
        "picture": res.data.picture,
        "payment": res.data.payment,
        "sender_name": res.data.sender_name,
        "sender_email": res.data.sender_email,
        "sender_phone": res.data.sender_phone,
        "sender_address": res.data.sender_address,
        "sender_place": res.data.sender_place,
        "receive_name": res.data.receive_name,
        "receive_email": res.data.receive_email,
        "receive_phone": res.data.receive_phone,
        "receive_address": res.data.receive_address,
        "weight": res.data.weight,
        "height": res.data.height,
        "width": res.data.width,
        "length": res.data.length,
        "content": res.data.content,
        "coupon": res.data.coupon,
        "discount": res.data.discount,
        "final_payment": res.data.final_payment,
        "ups_tracking": res.data.ups_tracking,
        "date": dayjs(res.data.date).add(1, 'day').format("DD/MM/YYYY"),
        "product_type": res.data.product_type,
        "air_eta": dayjs(res.data.date).add(res.data.air_eta, 'day').format("DD/MM/YYYY"),
        "ocean_eta": dayjs(res.data.date).add(res.data.ocean_eta, 'day').format("DD/MM/YYYY"),
        "status_id": res.data.status_id,
        "ups_pickup_cost": res.data.ups_pickup_cost,
      });
    }
    else {
      setError(true);
      setData({});
    }
    setLoading({...loading, page: false});
  }

  const getProductType = () => {
    switch (data.product_type) {
      case "OCEAN":
        return "Marítimo";
      case "AIR":
        return "Aéreo";
      case "GROUND":
        return "Domestico";
      default:
        return "No definido";
    }
  }

  const getWeightKg = () => {
    return (data.weight / 2.2046).toFixed(2);
  }

  const getMeasurament = (type, height, length, width) => {
    if(type === "OCEAN") {
      return {
        "type": "Pies cúbicos",
        "value": ((height * length * width) / 1728).toFixed(2)
      }
    }
    else {
      return {
        "type": "Peso volumen",
        "value": ((height * length * width) / 166).toFixed(2)
      }
    }
  }

  const payPendingDebt = async () => {
    setLoading({...loading, payBtn: true});

    const jsn = {
      "id": data.id
    }

    const res = await payPendingDebtData(jsn);

    if(res.success === true) {
      window.location.href = res.data;
    }
    else {
      notifyAlert('danger', 'Mensaje', 'Error al tratar de procesar el pago');
    }
    
    setLoading({...loading, payBtn: false});
  }
  
  const checkInvoiceButton = () => {
    if(data.payment === 0 && Number(status) > 1) {
      return (
        <Button
          size='sm'
          style={{
            backgroundColor: getColor(),
            borderColor: getColor(),
            color: "#fff"
          }}
          onClick={() => downloadInvoice()}
        > Descargar factura </Button>
      );
    }
  }

  const downloadInvoice = async () => {
    setLoading({...loading, invoiceBtn: true});

    const res = await downloadInvoiceData(data.tracking_number);

    if(res.success === true) {
      const link = document.createElement('a');
      link.download = `INVOICE - ${String(data.tracking_number).toUpperCase()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al descargar la etiqueta");
    }
    setLoading({...loading, invoiceBtn: false});
  }
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card 
              style={{
                border:  (data.payment === 1) ? "3px solid #f5365c" : ""
              }}
            >
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> 🔍 Tracking #{String(data.tracking_number).toUpperCase()} </h3>
                    <h3> UPS Tracking: {String(data.ups_tracking).toUpperCase()} </h3>
                  </div>
                    <div 
                      style={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}
                      className="col mb-0 float-rigth">
                      {
                        (data.payment === 1) ? <> 
                          <div 
                            style={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "right",
                              justifyContent: "flex-end"
                            }}
                            className="col mb-0 float-rigth">
                              <span style={{
                                color: "#f5365c",
                              }}>
                                Este paquete ha sido recibido, pendiente por pago 
                              </span>
                            <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                            <Button
                              size="sm"
                              style={{
                                backgroundColor: "#f5365c",
                                borderColor: "#f5365c",
                                marginLeft: "10px",
                                color: "#fff"
                              }}
                              onClick={() => payPendingDebt()}>
                              Realizar pago
                            </Button>
                          </div>
                        </> : <>
                          <div className="col mb-0 float-right">
                            <div style={{
                              textAlign: "right",
                            }}>
                              { checkInvoiceButton() }
                            </div>
                          </div>
                        </>
                      }
                      {
                        (data.status_id === 2) ? <></> : 
                        <a 
                          className='btn btn-primary btn-sm'
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            display: "block",
                            backgroundColor: getColor(),
                            borderColor: getColor()
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://es-us.ups.com/track?loc=es_US&&tracknum=${data.ups_tracking}`}>Rastrear en UPS </a>
                      }
                      </div>
                </Row>
              </CardHeader>
                
              <CardBody>
                <div className='paddingHorizontal'>
                  {
                    (loading.page === true) ? <>
                      <div className="text-center">
                        <Spinner 
                          style={{
                            color: getColor()
                          }}
                        />
                      </div>
                    </> : <>
                      {
                        (error === true) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>
                          {
                            (data.product_type).includes(["GROUND"]) ? <></> : <>
                              <Row style={{
                                marginBottom: "20px"
                              }}>
                                <Col>
                                  <ul style={{
                                    padding: "0px",
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly"
                                  }}>
                                    <li>
                                      <div className='trackingView' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                      }}>
                                        <img 
                                          alt='label'
                                          style={{ 
                                            marginBottom: "5px",
                                            width: "40px", 
                                            height: "40px", 
                                            objectFit: "contain" 
                                          }}
                                          src = {require("./../../assets/img/label.png").default} />
                                        <div 
                                          style={{ 
                                            backgroundColor: (status >= 1) ? getColor() : "#8C8C8C", 
                                            width: "10px", 
                                            height: "10px"
                                          }}
                                          className="icon icon-shape text-white rounded-circle shadow">
                                        </div>
                                        <span style={{fontSize: "14px"}}> Etiqueta creada </span>
                                      </div>
                                    </li>

                                    <li>
                                      <div className='trackingView' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                      }}>
                                        <img 
                                          alt='warehouse'
                                          style={{ 
                                            marginBottom: "5px", 
                                            width: "40px", 
                                            height: "40px", 
                                            objectFit: "contain" 
                                          }}
                                          src = {require("./../../assets/img/warehouse.png").default} />
                                        <div 
                                          style={{ 
                                            backgroundColor: (status >= 2) ? getColor() : "#8C8C8C", 
                                            width: "10px", 
                                            height: "10px"
                                          }}
                                          className="icon icon-shape text-white rounded-circle shadow">
                                        </div>
                                        <span style={{fontSize: "14px"}}> En almacen </span>
                                      </div>
                                    </li>

                                    <li>
                                      <div className='trackingView' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                      }}>
                                        <img 
                                          alt='transit'
                                          style={{ 
                                            marginBottom: "5px",
                                            width: "40px", 
                                            height: "40px", 
                                            objectFit: "contain" 
                                          }}
                                          src = {require("./../../assets/img/transit.png").default} />
                                        <div 
                                          style={{ 
                                            backgroundColor: (status >= 3) ? getColor() : "#8C8C8C", 
                                            width: "10px", 
                                            height: "10px"
                                          }}
                                          className="icon icon-shape text-white rounded-circle shadow">
                                        </div>
                                        {
                                          window.screen.width < 426 ? <span style={{fontSize: "14px"}}> Enviado </span> :
                                          <span style={{fontSize: "14px"}}> Enviado / transito </span>
                                        }
                                      </div>
                                    </li>

                                    <li>
                                      <div className='trackingView' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                      }}>
                                        <img 
                                          alt='onsite'
                                          style={{ 
                                            marginBottom: "5px",
                                            width: "40px", 
                                            height: "40px", 
                                            objectFit: "contain" 
                                          }}
                                          src = {require("./../../assets/img/onsite.png").default} />
                                        <div 
                                          style={{ 
                                            backgroundColor: (status >= 4) ? getColor() : "#8C8C8C", 
                                            width: "20px", 
                                            height: "20px"
                                          }}
                                          className="icon icon-shape text-white rounded-circle shadow">
                                        </div>
                                        <span style={{fontSize: "14px"}}> En destino </span>
                                      </div>
                                    </li>

                                    <li>
                                      <div className='trackingView' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                      }}>
                                        <img 
                                          alt='delivery'
                                          style={{
                                            marginBottom: "5px",
                                            width: "40px", 
                                            height: "40px", 
                                            objectFit: "contain"
                                          }}
                                          src = {require("./../../assets/img/deliver.png").default} />
                                        <div 
                                          style={{ 
                                            backgroundColor: (status >= 5) ? getColor() : "#8C8C8C", 
                                            width: "20px", 
                                            height: "20px"
                                          }}
                                          className="icon icon-shape text-white rounded-circle shadow">
                                        </div>
                                        <span style={{fontSize: "14px"}}> Entregado </span>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </>
                          }

                          <Row>
                            <Col style={{textAlign: "center"}}>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={data.picture} />
                            </Col>

                            <Col>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                              }}>
                                <li>
                                  <h2> {data.product_name} </h2>
                                </li>

                                <li>
                                  <label> 
                                    <b>
                                      {
                                        (data.final_payment === null) ? "💵 Pago: " : "💵 Primer pago: "
                                      }
                                    </b>
                                    ${Number(data.price).toFixed(2)}
                                  </label>
                                </li>

                                {
                                  (data.ups_pickup_cost !== null) ? <> 
                                    <li>
                                      <label> 
                                        <b>
                                          💵 UPS Pickup: 
                                        </b>
                                        ${Number(data.ups_pickup_cost).toFixed(2)}
                                      </label>
                                    </li>
                                  </>: <></>
                                }

                                {
                                  (data.final_payment === 0) ? <></> : <>
                                    {
                                      (data.final_payment !== null) ? <>
                                        <li>
                                          <label> 
                                            <b>
                                              {
                                                (data.payment === 1) ? "💵 Deuda: " : "💵 Segundo pago: "
                                              } 
                                            </b>
                                            ${Number(data.final_payment).toFixed(2)}
                                          </label>
                                        </li>
                                      </> : <></>
                                    }
                                  </>
                                }
                                {
                                  (data.final_payment !== null) ? <>
                                  <li>
                                    <label> <b>💵 Pago total: </b> ${ (Number(data.price) + Number(data.final_payment)).toFixed(2) }</label>
                                  </li>
                                  </> : <></>
                                }
                                <li>
                                  <label> <b>📅 Compra: </b> {data.date} </label>
                                </li>
                                {
                                  (status > 2) ? <>
                                    <li>
                                      <label> <b>📆 ETA: </b> 
                                        {
                                          (data.product_type === "OCEAN") ? data.ocean_eta : data.air_eta
                                        }
                                      </label>
                                    </li>
                                  </> : <></>
                                }
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="4">
                              <h3> 📤 Envía </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.sender_name}
                                </li>
                                <li> 
                                  📱 {data.sender_phone}
                                </li>
                                <li> 
                                  ✉ {data.sender_email}
                                </li>
                                <li> 
                                  <b>Direccion: </b> {data.sender_address}
                                </li>
                                <li> 
                                  {data.sender_place}
                                </li>
                              </ul>
                            </Col>
                            
                            <Col lg="4">
                              <h3> 📥 Recibe </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.receive_name}
                                </li>
                                <li> 
                                  📱 {data.receive_phone}
                                </li>
                                <li> 
                                  ✉ {data.receive_email}
                                </li>
                                <li> 
                                <b>Direccion: </b> {data.receive_address}
                                </li>
                              </ul>
                            </Col>

                            <Col lg="4">
                              <h3> 📦 Paquete Recibe </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  <b> Servicio: </b> { getProductType(data.product_type) }
                                </li>
                                {
                                  (data.weight !== null) ? <>
                                    <li> 
                                      <b> Dimensiones: </b> {data.length}x{data.width}x{data.height}
                                    </li>
                                  </> : <></>
                                }
                                {
                                  (data.weight !== null) ? <>
                                    <li>
                                      <b> Peso: </b> {data.weight}lbs - {getWeightKg(data.weight)}kg
                                    </li>
                                  </> : <></>
                                }
                                {
                                  (data.weight !== null) ? <>
                                  <li>
                                    <b> 
                                      {getMeasurament(data.product_type, data.height, data.length, data.width).type}: 
                                    </b>
                                      {getMeasurament(data.product_type, data.height, data.length, data.width).value}
                                  </li>
                                  </> : <></>
                                  }
                                <li> 
                                  <b> Contenido: </b> {data.content}
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                <Button color="gray" to="/admin/purchase" tag={Link}> Regresar </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>

  )
}
