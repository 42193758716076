/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Container, Row, Col, Button, Input, FormGroup } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getPackageByIdentifierWarehouseData, addDimensionToPackageData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

export default function ProcessDomestic() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: false, payBtn: false });
  const [date, setDate] = useState("");
  const [height, setHeight] = useState("0");
  const [width, setWidth] = useState("0");
  const [length, setLength] = useState("0");
  const [weight, setWeight] = useState("0");
  const [error, setError] = useState(false);
  const [notes, setNotes] = useState("");
  const [data, setData] = useState({
    "id": "",
    "status": "",
    "status_id": "",
    "product_name": "",
    "package_number": "",
    "tracking_number": "",
    "price": "",
    "picture": "",
    "payment": "",
    "sender_name": "",
    "sender_email": "",
    "sender_phone": "",
    "sender_address": "",
    "sender_place": "",
    "receive_name": "",
    "receive_email": "",
    "receive_phone": "",
    "receive_address": "",
    "weight": "",
    "height": "",
    "width": "",
    "length": "",
    "content": "",
    "coupon": "",
    "discount": "",
    "final_payment": "",
    "ups_tracking": "",
    "date": "",
    "product_type": "",
    'air_eta': "",
    'ocean_eta': "",
    'process_date': "",
    'lot_tracking': ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataByIdentifier();
  }, []);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getDataByIdentifier = async () => {
    setLoading({...loading, page: true});

    console.log(id);

    const res = await getPackageByIdentifierWarehouseData(id);

    if(res.success === true) {
      setError(false);
      setData({
        "id": res.data.id,
        "status": res.data.status,
        "product_name": res.data.product_name,
        "package_number": res.data.package_number,
        "tracking_number": res.data.tracking_number,
        "price": res.data.price,
        "picture": res.data.picture,
        "payment": res.data.payment,
        "sender_name": res.data.sender_name,
        "sender_email": res.data.sender_email,
        "sender_phone": res.data.sender_phone,
        "sender_address": res.data.sender_address,
        "sender_place": res.data.sender_place,
        "receive_name": res.data.receive_name,
        "receive_email": res.data.receive_email,
        "receive_phone": res.data.receive_phone,
        "receive_address": res.data.receive_address,
        "weight": res.data.weight,
        "height": res.data.height,
        "width": res.data.width,
        "length": res.data.length,
        "content": res.data.content,
        "ups_tracking": res.data.ups_tracking,
        "date": dayjs(res.data.date).add(1, 'day').format("DD/MM/YYYY"),
        "product_type": res.data.product_type,
        'ups_prn': res.data.ups_prn,
        'process_date': res.data.process_date
      });
    }
    else {
      setError(true);
      setData({});
    }
    setLoading({...loading, page: false});
  }

  const getProductType = () => {
    switch (data.product_type) {
      case "OCEAN":
        return "Marítimo";
      case "AIR":
        return "Aéreo";
      case "LAND":
        return "Terrestre";
      default:
        return "No definido";
    }
  }

  const getWeightKg = (value) => {
    return (value * 0.45359237).toFixed(2);
  }
  
  const addDimensionToPackage = async () => {
    setLoading({...loading, payBtn: true});

    if(String(height).trim() === "" || String(width).trim() === "" || String(length).trim() === "" || String(weight).trim() === "") {
      notifyAlert("danger", "Error", "Los campos no pueden estar vacíos");
    }
    else if(Number(height) <= 0 || Number(width) <= 0 || Number(length) <= 0 || Number(weight) <= 0) {
      notifyAlert("danger", "Error", "Los campos no pueden ser menores o iguales a 0");
    }
    else {
      const jsn = {
        "id": data.id,
        "height": height,
        "width": width,
        "length": length,
        "weight": weight,
        "notes": notes,
        "date": date
      }

      const res = await addDimensionToPackageData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "Se ha agregado las dimensiones al paquete");
        getDataByIdentifier();
      }
      else {
        notifyAlert("danger", "Error", "No se ha podido agregar las dimensiones al paquete");
      }
      
    }
    setLoading({...loading, payBtn: false});
  }

  const getMeasurament = (type, height, length, width) => {
    if(type === "OCEAN") {
      return {
        "type": "Pies cúbicos",
        "value": ((height * length * width) / 1728).toFixed(2)
      }
    }
    else {
      return {
        "type": "Peso volumen",
        "value": ((height * length * width) / 166).toFixed(2)
      }
    }
  }
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card 
              style={{
                border:  (data.payment === 1) ? "3px solid #f5365c" : ""
              }}
            >
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> VTS Tracking: #{String(data.tracking_number).toUpperCase()} </h3>
                    <h3> UPS Tracking: {String(data.ups_tracking).toUpperCase()} </h3>
                  </div>
                  {
                    (data.payment === 1) ? <> 
                      <div 
                        style={{
                          textAlign: "right",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end"
                        }}
                        className="col mb-0 float-rigth">
                          <span style={{
                            color: "#f5365c",
                          }}>
                            Este paquete tiene un pago pendiente para ser liberado
                          </span>
                        <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                      </div>
                    </> : <></>
                  }
                </Row>
              </CardHeader>
                
              <CardBody>
                <div className='paddingHorizontal'>
                  {
                    (loading.page === true) ? <>
                      <div className="text-center">
                        <Spinner 
                          style={{
                            color: getColor
                          }}
                        />
                      </div>
                    </> : <>
                      {
                        (error === true) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>

                          <Row>
                            <Col lg="6" style={{textAlign: "center"}}>
                              <h2> {data.product_name} </h2>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={data.picture} />

                              <h3> 📤 Remitente </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.sender_name}
                                </li>
                                <li> 
                                  📱 {data.sender_phone}
                                </li>
                                <li> 
                                  ✉ {data.sender_email}
                                </li>
                                <li> 
                                  <b>Direccion: </b> {data.sender_address}
                                </li>
                                <li> 
                                  {data.sender_place}
                                </li>
                              </ul>

                              {/*
                                <h3> 📥 Consignatario </h3>
                                <ul style={{
                                  listStyle: 'none',
                                  padding: '0px',
                                  lineHeight: '2',
                                }}>
                                  <li> 
                                    {data.receive_name}
                                  </li>
                                  <li> 
                                    📱 {data.receive_phone}
                                  </li>
                                  <li> 
                                    ✉ {data.receive_email}
                                  </li>
                                  <li> 
                                  <b>Direccion: </b> {data.receive_address}
                                  </li>
                                </ul>
                              */}

                              <h3> 📦 Datos del Paquete </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  <b> Servicio: </b> { getProductType(data.product_type) }
                                </li>
                                {
                                  (data.weight !== null) ? <>
                                    <li> 
                                      <b> Dimensiones: </b> {data.length}" x {data.width}" x {data.height}"
                                    </li>
                                  </> : <></>
                                }
                                {
                                  (data.weight !== null) ? <>
                                    <li>
                                      <b> Peso: </b> {data.weight}lbs - {getWeightKg(data.weight)}kg
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <b> {getMeasurament(data.product_type, data.height, data.length, data.width).type}: </b>
                                    {getMeasurament(data.product_type, data.height, data.length, data.width).value}
                                </li>
                                <li> 
                                  <b> Contenido: </b> {data.content}
                                </li>
                              </ul>
                            </Col>
                            {
                              (data.payment === 1) ? <>
                                <Col lg="6">
                                  <h2> Pago Pendiente </h2>
                                  <p> Este paquete tiene un pago pendiente por lo tanto no puede ser procesado hasta que se realice el pago correspondiente. </p>
                                  <div className="float-right">
                                    <Button 
                                      tag={Link}
                                      color="gray" 
                                      to="/admin/process"> Regresar </Button>
                                  </div>
                                </Col>
                              </> : <>
                                {
                                  (data.process_date !== null) ? <>
                                    <Col lg="6">
                                      <h2> Paquete entregado </h2>
                                      <p> Este paquete ha sido entregado en su destino. </p>
                                      <div className="float-right">
                                        <Button 
                                          tag={Link}
                                          color="gray" 
                                          to="/admin/process"> Regresar </Button>
                                      </div>
                                    </Col>
                                  </> :
                                  <>
                                    <Col lg="6">
                                      <Row>
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Fecha de recepción </label>
                                            <Input
                                              type='date'
                                              value={date}
                                              className="form-control"
                                              onChange={val => setDate(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Peso </label>
                                            <Input
                                              value={weight}
                                              className="form-control"
                                              onChange={val => setWeight(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
    
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Largo </label>
                                            <Input
                                              value={length}
                                              className="form-control"
                                              onChange={val => setLength(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                        
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Ancho </label>
                                            <Input
                                              value={width}
                                              className="form-control"
                                              onChange={val => setWidth(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
    
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Alto </label>
                                            <Input
                                              value={height}
                                              className="form-control"
                                              onChange={val => setHeight(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
    
                                        <Col lg="12">
                                          <FormGroup>
                                            <label className="form-control-label"> Comentario </label>
                                            <Input
                                              type='textarea'
                                              value={notes}
                                              className="form-control"
                                              onChange={val => setNotes(val.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                  
                                        <Col lg="12" >
                                          <br />
                                          <div className="float-right">
                                            {
                                              (loading.payBtn) ? <>
                                                <Spinner style={{
                                                  color: getColor(),
                                                }}
                                                />
                                              </> : 
                                              <>
                                                <Button color="gray" to="/admin/process" tag={Link}> Regresar </Button>
                                                <Button 
                                                  style={{
                                                    backgroundColor: getColor(),
                                                    border: getColor(),
                                                    color: "#fff"
                                                  }}
                                                  onClick={() => addDimensionToPackage()}
                                                  > Guardar </Button>
                                              </>
                                            }
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                }
                              </>
                            }
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
