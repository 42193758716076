/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { setNewUsersData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

const NewUsers = () => {
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState('0');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const addNewUsers = async () => {
    setLoading(true);
    
    if(String(name).trim() === "") {
      notifyAlert('danger', 'Error', 'Debe ingresar el nombre del usuario');
    }
    else if(String(email).trim() === "") {
      notifyAlert('danger', 'Error', 'Debe ingresar el correo del usuario');
    }
    else if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email)) {
      notifyAlert('danger', 'Error', 'Debe ingresar un correo valido');
    }
    else if(String(role).trim() === "") {
      notifyAlert('danger', 'Error', 'Debe seleccionar el rol del usuario');
    }
    else {
      const jsn = {
        "name": name,
        "email": email,
        "active": active,
        "role": role
      }

      const res = await setNewUsersData(jsn);

      if(res.success === true) {
        notifyAlert('success', 'Exito', 'Usuario creado correctamente');
        setName('');
        setEmail('');
        setActive('1');
        setRole('');
      }
      else {
        notifyAlert('danger', 'Error', "Error al crear el usuario");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Usuarios </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Email </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Rol </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={role}
                          onChange={val => setRole(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          <option value="1"> Gerente </option>
                          <option value="2"> Operador </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/users" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewUsers()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default NewUsers;