import { Container, Navbar } from "reactstrap";

export default function AuthHeader({color, logo, slogan}) {
  return (
    <>
    <Navbar
      style={{
        backgroundColor: "#ffffff",
      }}
      expand="md">
        <Container>
          <div className="text-center" style={{width: '100%'}}>
            <div>
              <img style={{
                width: '120px',
                height: 'auto',
              }} alt="logo" src={logo} />

              <p style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '0px',
                color: color
              }}> {slogan} </p>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

