/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Container, Row, Col, Button } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getDataByTrackingData } from "../../api/open";
import OpenHeader from 'components/Headers/OpenHeader';

export default function OpenViewTracking () {
  const { track } = useParams();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    "id": "",
    "status": "",
    "status_id": "",
    "product_name": "",
    "package_number": "",
    "tracking_number": "",
    "picture": "",
    "payment": "",
    "weight": "",
    "height": "",
    "width": "",
    "length": "",
    "ups_tracking": "",
    "date": "",
    "product_type": "",
    "air_eta": "",
    "ocean_eta": ""
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataByTracking();
  }, []);
  
  const getDataByTracking = async () => {
    setLoading(true);
    const res = await getDataByTrackingData(track);

    if(res.success === true) {
      setError(false);
      setStatus(res.data.status_id);
      setData({
        "id": res.data.id,
        "status": res.data.status,
        "product_name": res.data.product_name,
        "package_number": res.data.package_number,
        "tracking_number": res.data.tracking_number,
        "picture": res.data.picture,
        "payment": res.data.payment,
        "weight": res.data.weight,
        "height": res.data.height,
        "width": res.data.width,
        "length": res.data.length,
        "ups_tracking": res.data.ups_tracking,
        "date": dayjs(res.data.date).add(1, 'day').format("DD/MM/YYYY"),
        "product_type": res.data.product_type,
        "air_eta": dayjs(res.data.date).add(res.data.air_eta, 'day').format("DD/MM/YYYY"),
        "ocean_eta": dayjs(res.data.date).add(res.data.ocean_eta, 'day').format("DD/MM/YYYY"),
      });
    }
    else {
      setError(true);
      setData({});
    }
    setLoading(false);
  }

  const getProductType = () => {
    switch (data.product_type) {
      case "OCEAN":
        return "Marítimo";
      case "AIR":
        return "Aéreo";
      case "GROUND":
        return "Domestico";
      default:
        return "No definido";
    }
  }

  return (
    <>
      <Container fluid>
        <OpenHeader />
        {
          (loading) ? <>
            <div className="text-center">
              <Spinner 
                style={{
                  color: getColor()
                }}
              />
            </div>
          </> : <>
            <Row>
              <Col lg="12">
                <div 
                  style={{
                    border:  (data.payment === 1) ? "3px solid #f5365c" : "",
                    padding: "5px"
                  }}
                >
                  <div className="border-0">
                    <Row>
                      <div className="col mb-0 float-left">
                        <h3> 🔍 Tracking #: {String(data.tracking_number).toUpperCase()} </h3>
                        <h3> UPS Tracking: {String(data.ups_tracking).toUpperCase()} </h3>
                      </div>
                      {
                        (data.payment === 1) ? <> 
                          <div 
                            style={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end"
                            }}
                            className="col mb-0 float-rigth">
                              <span style={{
                                color: "#f5365c",
                              }}>
                                Debe iniciar sesión para realizar el pago pendiente.
                              </span>
                            <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                          </div>
                        </> : <>
                        </>
                      }
                    </Row>
                  </div>
                    
                  <div>
                    <div className='paddingHorizontal'>
                      {
                        (error) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>
                        {
                          (data.product_type).includes(["GROUND"]) ? <></> : <>
                            <Row style={{
                              marginBottom: "20px"
                            }}>
                              <Col>
                                <ul style={{
                                  padding: "0px",
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly"
                                }}>
                                  <li>
                                    <div className='trackingView' style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>
                                      <img 
                                        alt='label'
                                        style={{ 
                                          marginBottom: "5px",
                                          width: "40px", 
                                          height: "40px", 
                                          objectFit: "contain" 
                                        }}
                                        src = {require("./../../assets/img/label.png").default} />
                                      <div 
                                        style={{ 
                                          backgroundColor: (status >= 1) ? getColor() : "#8C8C8C", 
                                          width: "10px", 
                                          height: "10px"
                                        }}
                                        className="icon icon-shape text-white rounded-circle shadow">
                                      </div>
                                      <span style={{fontSize: "14px"}}> Etiqueta creada </span>
                                    </div>
                                  </li>
      
                                  <li>
                                    <div className='trackingView' style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>
                                      <img 
                                        alt='warehouse'
                                        style={{ 
                                          marginBottom: "5px", 
                                          width: "40px", 
                                          height: "40px", 
                                          objectFit: "contain" 
                                        }}
                                        src = {require("./../../assets/img/warehouse.png").default} />
                                      <div 
                                        style={{ 
                                          backgroundColor: (status >= 2) ? getColor() : "#8C8C8C", 
                                          width: "10px", 
                                          height: "10px"
                                        }}
                                        className="icon icon-shape text-white rounded-circle shadow">
                                      </div>
                                      <span style={{fontSize: "14px"}}> En almacen </span>
                                    </div>
                                  </li>
      
                                  <li>
                                    <div className='trackingView' style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>
                                      <img 
                                        alt='transit'
                                        style={{ 
                                          marginBottom: "5px",
                                          width: "40px", 
                                          height: "40px", 
                                          objectFit: "contain" 
                                        }}
                                        src = {require("./../../assets/img/transit.png").default} />
                                      <div 
                                        style={{ 
                                          backgroundColor: (status >= 3) ? getColor() : "#8C8C8C", 
                                          width: "10px", 
                                          height: "10px"
                                        }}
                                        className="icon icon-shape text-white rounded-circle shadow">
                                      </div>
                                      {
                                        window.screen.width < 426 ? <span style={{fontSize: "14px"}}> Enviado </span> :
                                        <span style={{fontSize: "14px"}}> Enviado / transito </span>
                                      }
                                    </div>
                                  </li>
      
                                  <li>
                                    <div className='trackingView' style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>
                                      <img 
                                        alt='onsite'
                                        style={{ 
                                          marginBottom: "5px",
                                          width: "40px", 
                                          height: "40px", 
                                          objectFit: "contain" 
                                        }}
                                        src = {require("./../../assets/img/onsite.png").default} />
                                      <div 
                                        style={{ 
                                          backgroundColor: (status >= 4) ? getColor() : "#8C8C8C", 
                                          width: "20px", 
                                          height: "20px"
                                        }}
                                        className="icon icon-shape text-white rounded-circle shadow">
                                      </div>
                                      <span style={{fontSize: "14px"}}> En destino </span>
                                    </div>
                                  </li>
      
                                  <li>
                                    <div className='trackingView' style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>
                                      <img 
                                        alt='delivery'
                                        style={{
                                          marginBottom: "5px",
                                          width: "40px", 
                                          height: "40px", 
                                          objectFit: "contain"
                                        }}
                                        src = {require("./../../assets/img/deliver.png").default} />
                                      <div 
                                        style={{ 
                                          backgroundColor: (status >= 5) ? getColor() : "#8C8C8C", 
                                          width: "20px", 
                                          height: "20px"
                                        }}
                                        className="icon icon-shape text-white rounded-circle shadow">
                                      </div>
                                      <span style={{fontSize: "14px"}}> Entregado </span>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </>
                        }
                          <Row>
                            <Col lg="6" style={{textAlign: "center"}}>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={data.picture} />
                            </Col>
    
                            <Col lg="6">
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                              }}>
                                <li>
                                  <h2> {data.product_name} </h2>
                                </li>
                                <li>
                                  <label> <b>✅ </b> { data.status } </label>
                                </li>
                                <li>
                                  <label> <b>📅 Compra: </b> {data.date} </label>
                                </li>
                                {
                                  (status > 2) ? <>
                                    <li>
                                      <label> <b>📆 ETA: </b> 
                                        {
                                          (data.product_type === "OCEAN") ? data.ocean_eta : data.air_eta
                                        }
                                      </label>
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <b> Servicio: </b> { getProductType(data.product_type) }
                                </li>
                                <li> 
                                  <b> Dimensiones: </b> {data.length}x{data.width}x{data.height}
                                </li>
                              </ul>
                            </Col>
                          </Row>
    
                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                <Button color="gray" to="/tracking" tag={Link}> Regresar </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </div>
                  </div>
    
                </div>
              </Col>
            </Row>
          </>
        }
      </Container>
    </>
  )
}
