/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Table, Row, Button, Col, FormGroup, Input, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getColor } from "utils/token";
import { getAllSenderAddressData,
  createSenderAddressData, 
  updateSenderAddressData,
  deleteSenderAddressData } from "../../../api/users";

export default function AddressUS() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ load: false, table: true});
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [data, setData] = useState([]);
  const [error, setErrror] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [delId, setDelId] = useState(0);

  const [titleModal, setTitleModal] = useState('');
  const [nameModal, setNameModal] = useState('');
  const [lineOneModal, setLineOneModal] = useState('');
  const [lineTwoModal, setLineTwoModal] = useState('');
  const [emailModal, setEmailModal] = useState('');
  const [phoneModal, setPhoneModal] = useState('');
  const [stateModal, setStateModal] = useState('');
  const [cityModal, setCityModal] = useState('');
  const [zipCodeModal, setZipCodeModal] = useState('');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    getAllSenderAddress();
  },[]);

  const getAllSenderAddress = async () => {
    const res = await getAllSenderAddressData();

    if(res.success === true) {
      setErrror(false);
      setData(res.data);
    }
    else {
      setErrror(true);
    }

    setLoading({...loading, table: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});

    if(String(title).trim() === "") {
      notifyAlert("default", "Mensaje", "El titulo es requerido");
    }
    else if(String(name).trim() === "") {
      notifyAlert("default", "Mensaje", "El nombre es requerido");
    }
    else if (String(email).trim() === "") {
      notifyAlert("default", "Mensaje", "El correo es requerido");
    }
    else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(email).trim()) === false) {
      notifyAlert("default", "Mensaje", "El correo no es valido");
    }
    else if (String(phone).trim() === "") {
      notifyAlert("default", "Mensaje", "El telefono es requerido");
    }
    else if (String(lineOne).trim() === "") {
      notifyAlert("default", "Mensaje", "La dirección es requerida");
    }
    else if (String(state).trim() === "") {
      notifyAlert("default", "Mensaje", "El estado es requerido");
    }
    else if (String(city).trim() === "") {
      notifyAlert("default", "Mensaje", "La ciudad es requerida");
    }
    else if (String(zipCode).trim() === "") {
      notifyAlert("default", "Mensaje", "El codigo postal es requerido");
    }
    else {
      const jsn = {
        "title": title,
        "name": name,
        "email": email,
        "phone": phone,
        "lineOne": lineOne,
        "lineTwo": lineTwo,
        "state": state,
        "city": city,
        "zip_code": zipCode,
      }
      
      const res = await createSenderAddressData(jsn);
      
      if(res.success === true) {
        notifyAlert("success", "Mensaje", "Dirección guardada correctamente");
        setTitle('');
        setLineOne('');
        setLineTwo('');
        setState('');
        setCity('');
        setZipCode('');
        getAllSenderAddress();
      }
      else {
        notifyAlert("danger", "Mensaje", "Error al guardar la dirección");
      }
    }

    setLoading({...loading, save: false});
  }

  const setUpdate = async (id) => {
    setModal(!modal);
    const dataValor = data[id];
    
    setDelId(dataValor.id);
    setNameModal(dataValor.name);
    setTitleModal(dataValor.title);
    setEmailModal(dataValor.email);
    setPhoneModal(dataValor.phone);
    setLineOneModal(dataValor.lineOne);
    setLineTwoModal(dataValor.lineTwo);
    setStateModal(dataValor.state);
    setCityModal(dataValor.city);
    setZipCodeModal(dataValor.zip_code);
  }

  const updateSenderAddress = async () => {
    setLoading({...loading, save: true});

    if(String(titleModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El titulo es requerido");
    }
    else if(String(nameModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El nombre es requerido");
    }
    else if (String(lineOneModal).trim() === "") {
      notifyAlert("default", "Mensaje", "La dirección es requerida");
    }
    else if (String(emailModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El correo es requerido");
    }
    else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(emailModal).trim()) === false) {
      notifyAlert("default", "Mensaje", "El correo no es valido");
    }
    else if (String(phoneModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El telefono es requerido");
    }
    else if (String(stateModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El estado es requerido");
    }
    else if (String(cityModal).trim() === "") {
      notifyAlert("default", "Mensaje", "La ciudad es requerida");
    }
    else if (String(zipCodeModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El codigo postal es requerido");
    }
    else {
      const jsn = {
        'title': titleModal,
        'name': nameModal,
        'lineOne': lineOneModal,
        'lineTwo': lineTwoModal,
        'state': stateModal,
        'city': cityModal,
        'zip_code': zipCodeModal,
        'email': emailModal,
        'phone': phoneModal,
      }

      const res = await updateSenderAddressData(jsn, delId);

      if(res.success === true) {
        notifyAlert("success", "Mensaje", "Dirección guardada correctamente");
        setDelId(0);
        setTitleModal("");
        setLineOneModal("");
        setLineTwoModal("");
        setStateModal("");
        setCityModal("");
        setZipCodeModal("");
        getAllSenderAddress();
        setModal(!modal);
      }
      else {
        notifyAlert("danger", "Mensaje", "Error al guardar la dirección");
      }
    }

    setLoading({...loading, save: false});
  }

  const deleteAddressModal = async (id) => {
    setModalDelete(!modalDelete);
    setDelId(id);
  }

  const deleteAddress = async () => {
    const res = await deleteSenderAddressData(delId);

    if(res.success === true) {
      setModalDelete(!modalDelete);
      notifyAlert("success", "Mensaje", "Dirección eliminada correctamente");
      getAllSenderAddress();
    }
    else {
      notifyAlert("danger", "Mensaje", "Error al eliminar la dirección");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div style={{
        padding: "10px"
      }}>
        <div className='paddingHorizontal'>
          <Row>
            <Col>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Titulo </label>
                    <Input
                      placeholder="Mi casa"
                      value={title}
                      className="form-control"
                      onChange={val => setTitle(val.target.value)}/>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Nombre </label>
                    <Input
                      placeholder="John Doe"
                      value={name}
                      className="form-control"
                      onChange={val => setName(val.target.value)}/>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Email </label>
                    <Input
                      placeholder="example@email.com"
                      value={email}
                      className="form-control"
                      onChange={val => setEmail(val.target.value)}/>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Teléfono </label>
                    <Input
                      placeholder="+1 123 456 7890"
                      value={phone}
                      className="form-control"
                      onChange={val => setPhone(val.target.value)}/>
                  </FormGroup>
                </Col>

                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label"> Dirección </label>
                    <Input
                      placeholder="Linea 1"
                      value={lineOne}
                      className="form-control"
                      onChange={val => setLineOne(val.target.value)}/>
                  </FormGroup>

                  <FormGroup>
                    <Input
                      placeholder="Linea 2 (Opcional)"
                      value={lineTwo}
                      className="form-control"
                      onChange={val => setLineTwo(val.target.value)}/>
                  </FormGroup>
                </Col>
                
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Estado </label>
                    <select 
                      value={state}
                      className='form-control'
                      onChange={(val) => setState(val.target.value)}>
                      <option value=""> Seleccionar </option>
                      <option value="AL"> Alabama </option>
                      <option value="AZ"> Arizona </option>
                      <option value="AR"> Arkansas </option>
                      <option value="CA"> California </option>
                      <option value="CO"> Colorado </option>
                      <option value="CT"> Connecticut </option>
                      <option value="DE"> Delaware </option>
                      <option value="FL"> Florida </option>
                      <option value="GA"> Georgia </option>
                      <option value="ID"> Idaho </option>
                      <option value="IL"> Illinois </option>
                      <option value="IN"> Indiana </option>
                      <option value="IA"> Iowa </option>
                      <option value="KS"> Kansas </option>
                      <option value="KY"> Kentucky </option>
                      <option value="LA"> Louisiana </option>
                      <option value="ME"> Maine </option>
                      <option value="MD"> Maryland </option>
                      <option value="MA"> Massachusetts </option>
                      <option value="MI"> Michigan </option>
                      <option value="MN"> Minnesota </option>
                      <option value="MS"> Mississippi </option>
                      <option value="MO"> Missouri </option>
                      <option value="MT"> Montana </option>
                      <option value="NE"> Nebraska </option>
                      <option value="NV"> Nevada </option>
                      <option value="NH"> New Hampshire </option>
                      <option value="NJ"> New Jersey </option>
                      <option value="NM"> New Mexico </option>
                      <option value="NY"> New York </option>
                      <option value="NC"> North Carolina </option>
                      <option value="ND"> North Dakota </option>
                      <option value="OH"> Ohio </option>
                      <option value="OK"> Oklahoma </option>
                      <option value="OR"> Oregon </option>
                      <option value="PA"> Pennsylvania </option>
                      <option value="RI"> Rhode Island </option>
                      <option value="SC"> South Carolina </option>
                      <option value="SD"> South Dakota </option>
                      <option value="TN"> Tennessee </option>
                      <option value="TX"> Texas </option>
                      <option value="UT"> Utah </option>
                      <option value="VT"> Vermont </option>
                      <option value="VA"> Virginia </option>
                      <option value="WA"> Washington </option>
                      <option value="WV"> West Virginia </option>
                      <option value="WI"> Wisconsin </option>
                      <option value="WY"> Wyoming </option>
                    </select>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Ciudad </label>
                    <Input
                      placeholder="Ciudad"
                      value={city}
                      className="form-control"
                      onChange={val => setCity(val.target.value)}/>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Codigo Postal </label>
                    <Input
                      placeholder="Codigo Postal"
                      value={zipCode}
                      className="form-control"
                      onChange={val => setZipCode(val.target.value)}/>
                  </FormGroup>
                </Col>
              </Row>
            
              <div className="float-right" style={{
                marginBottom: "20px"
              }}>
              {
                (loading.save) ? <div className="text-center">
                  <Spinner style={{
                    color: getColor()
                  }} />
                </div> :
                <>
                  <Button
                    style={{
                      backgroundColor: getColor(),
                      color: "#fff",
                      border: 0
                    }}
                    onClick={() => saveData()}
                    > Guardar
                  </Button>
                </>
              }
              </div>

            </Col>
          </Row>

          <Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col"> Titulo </th>
                  <th scope="col"> Nombre </th>
                  <th scope="col"> Estado </th>
                  <th scope="col"> Ciudad </th>
                  <th scope="col"> Dirección </th>
                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                {
                  (loading.table) ?
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <Spinner style={{color: '#004899'}} />
                    </td>
                  </tr>:
                  (error) ? 
                  <tr>
                    <td colSpan={10} className='text-muted text-center'>
                      <label> Ha ocurrido un error, por favor intente nuevamente otra vez.</label>
                      <br />
                      <Button 
                        color="secondary"> Cargar </Button>
                    </td>
                  </tr>:
                  (data.length === 0) ? 
                  <tr>
                    <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                  </tr>
                  :
                    data.map((item, key) => (
                      <tr key={key}>
                        <td> {item.title} </td>
                        <td> {item.name} </td>
                        <td> {item.state} </td>
                        <td> {item.city} </td>
                        <td> {
                          String(item.lineOne).length < 80 ? item.lineOne :
                           String(item.lineOne).substring(0,80).concat("...")
                          } 
                        </td>
                        <td>
                          <Button
                            color='danger'
                            size='sm'
                            onClick={() => deleteAddressModal(item.id)}
                            > Eliminar
                          </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(),
                              color: "#fff",
                              border: 0
                            }}
                            size='sm'
                            onClick={() => setUpdate(key)}
                          > Editar
                          </Button>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </Table>
          </Row>
        </div>
      </div>

      <Modal size='lg' isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal()}> Dirección </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Titulo </label>
                <Input
                  placeholder="Mi casa "
                  value={titleModal}
                  className="form-control"
                  onChange={val => setTitleModal(val.target.value)}/>
              </FormGroup>
            </Col>
            
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Nombre </label>
                <Input
                  placeholder="John Doe "
                  value={nameModal}
                  className="form-control"
                  onChange={val => setNameModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Email </label>
                <Input
                  placeholder="John Doe "
                  value={emailModal}
                  className="form-control"
                  onChange={val => setEmailModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Teléfono </label>
                <Input
                  placeholder="Mi casa "
                  value={phoneModal}
                  className="form-control"
                  onChange={val => setPhoneModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Dirección </label>
                <Input
                  placeholder="Linea 1"
                  value={lineOneModal}
                  className="form-control"
                  onChange={val => setLineOneModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <label className="form-control-label"> &nbsp; </label>
              <FormGroup>
                <Input
                  placeholder="Linea 2 (Opcional)"
                  value={lineTwoModal}
                  className="form-control"
                  onChange={val => setLineTwoModal(val.target.value)}/>
              </FormGroup>
            </Col>
            
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Estado </label>
                <select 
                  value={stateModal}
                  className='form-control'
                  onChange={(val) => setStateModal(val.target.value)}>
                  <option value=""> Seleccionar </option>
                  <option value="AL"> Alabama </option>
                  <option value="AZ"> Arizona </option>
                  <option value="AR"> Arkansas </option>
                  <option value="CA"> California </option>
                  <option value="CO"> Colorado </option>
                  <option value="CT"> Connecticut </option>
                  <option value="DE"> Delaware </option>
                  <option value="FL"> Florida </option>
                  <option value="GA"> Georgia </option>
                  <option value="ID"> Idaho </option>
                  <option value="IL"> Illinois </option>
                  <option value="IN"> Indiana </option>
                  <option value="IA"> Iowa </option>
                  <option value="KS"> Kansas </option>
                  <option value="KY"> Kentucky </option>
                  <option value="LA"> Louisiana </option>
                  <option value="ME"> Maine </option>
                  <option value="MD"> Maryland </option>
                  <option value="MA"> Massachusetts </option>
                  <option value="MI"> Michigan </option>
                  <option value="MN"> Minnesota </option>
                  <option value="MS"> Mississippi </option>
                  <option value="MO"> Missouri </option>
                  <option value="MT"> Montana </option>
                  <option value="NE"> Nebraska </option>
                  <option value="NV"> Nevada </option>
                  <option value="NH"> New Hampshire </option>
                  <option value="NJ"> New Jersey </option>
                  <option value="NM"> New Mexico </option>
                  <option value="NY"> New York </option>
                  <option value="NC"> North Carolina </option>
                  <option value="ND"> North Dakota </option>
                  <option value="OH"> Ohio </option>
                  <option value="OK"> Oklahoma </option>
                  <option value="OR"> Oregon </option>
                  <option value="PA"> Pennsylvania </option>
                  <option value="RI"> Rhode Island </option>
                  <option value="SC"> South Carolina </option>
                  <option value="SD"> South Dakota </option>
                  <option value="TN"> Tennessee </option>
                  <option value="TX"> Texas </option>
                  <option value="UT"> Utah </option>
                  <option value="VT"> Vermont </option>
                  <option value="VA"> Virginia </option>
                  <option value="WA"> Washington </option>
                  <option value="WV"> West Virginia </option>
                  <option value="WI"> Wisconsin </option>
                  <option value="WY"> Wyoming </option>
                </select>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Ciudad </label>
                <Input
                  placeholder="Ciudad"
                  value={cityModal}
                  className="form-control"
                  onChange={val => setCityModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Codigo Postal </label>
                <Input
                  placeholder="Codigo Postal"
                  value={zipCodeModal}
                  className="form-control"
                  onChange={val => setZipCodeModal(val.target.value)}/>
              </FormGroup>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
          <Button color="primary" onClick={() => updateSenderAddress()}> Guardar </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
        <ModalHeader toggle={() => setModalDelete()}> Mensaje </ModalHeader>
        <ModalBody>
          <label> ¿Está seguro que desea eliminar esta dirección? </label>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalDelete(!modalDelete)}> Cancelar </Button>
          <Button color="danger" onClick={() => deleteAddress()}> Eliminar </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}