/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Button, Card, CardBody, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import { setNewPassword } from "../../api/auth";

export default function Password() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [customize, setCustomize] = useState({
    logo: "",
    color: "",
    slogan: ""
  });

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    if (customizeData) {
      setCustomize({
        logo: customizeData.logo,
        color: customizeData.color,
        slogan: customizeData.slogan,
        id: customizeData.id
      })
    }
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const validData = () =>{
    return (
      email !== ""
    )
  }

  const sendData = async () => {
    setLoading(true);
    
    if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false) {
      notifyAlert('danger', "Message", "The email is invalid");
    }
    else {
      const res = await setNewPassword(email);
      
      if(res.success === true) {
        notifyAlert('success', "Message", res.data);
      }
      else {
        notifyAlert('danger', "Message", res.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
  
      <AuthHeader logo={customize.logo} />

      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="border-0 mb-0" style={{boxShadow: '0 0 0 0'}}>
              <CardBody className="py-lg-5">
                  <FormGroup>
                    <div style={{
                      fontSize: '13px',
                      color: '#8898aa',
                      textAlign: 'center',
                      marginBottom: '10px'
                    }}>
                      <span>Type you email to reset your password</span>
                    </div>

                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onChange={(val) => setEmail(val.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {
                      (!loading) ? <>
                        <Button
                          disabled={!validData()}
                          style={{
                            width: '100%',
                            backgroundColor: customize.color,
                            color: '#ffffff',
                          }}
                          onClick={() => sendData()}
                        > Reset password </Button>
                      </>
                      :
                      <>
                        <Spinner style={{
                          color: customize.color,
                        }} /> 
                      </>
                    }
                  </div>

                  <div style={{
                    marginTop: '15px',
                    float: 'right',
                    fontSize: '13px'
                  }}>
                    <Link 
                      to='/auth/login'>Back to login</Link>
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
