/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, {useState, useEffect} from "react";
import { Spinner, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { useLocation, Route, Switch, Link } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { getTokenData, getColor} from "utils/token";
import { routes } from "routes";
import { AuthContext } from "./../context/AuthContext";

function Admin() {
  const { signOut } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [admin, setAdmin] = useState(0);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [agency, setAgency] = useState({
    name: '',
    logo: '',
    color: '',
    role: ''
  });
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    getData();
  },[]);
  
  const getData = async () => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    const _name = getTokenData('vboxToken', 'name');
    const _picture = getTokenData('vboxToken', 'picture');
    const _admin = getTokenData('vboxToken', 'a');
    setAgency({
      name: customizeData.name,
      logo: customizeData.logo,
      color: customizeData.color,
      role: customizeData.role
    });
    setName(_name);
    setAdmin(_admin);
    setPicture(_picture);
    setLoading(false);
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && (prop.role).includes(admin)) {
        return (
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    console.log("MENU ==> ", e);
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      {
        (loading) ? <>
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner style={{color: getColor(1), width: '3rem', height: '3rem' }}/>
          </div>
        </> :
        <>
          {
            (admin === 1) ? <>
            <Sidebar
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              logo={{
                innerLink: "/",
                imgSrc: require("assets/img/logo.png").default,
                imgAlt: "...",
              }}
            />
            </> : <>
              <div>
                <Navbar 
                  dark={true} 
                  style={{
                    padding: "0rem 1rem",
                    backgroundColor: getColor(1)
                  }}
                >
                  <NavbarBrand href="/">
                    <img 
                      alt=""
                      style={{
                        width: '64px'
                      }}
                      src={require("assets/img/logo_white.png").default} />
                  </NavbarBrand>
                  <NavbarToggler onClick={toggle} />

                  <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                      <NavItem className={activeRoute(`/admin/calculator`)}>
                        <NavLink to="/admin/calculator" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-calculator"></i>
                          <span className="nav-link-text"> COTIZAR </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/services`)}>
                        <NavLink to="/admin/services" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-store"></i>
                          <span className="nav-link-text">ENVIAR</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/tracking`)}>
                        <NavLink to="/admin/tracking" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-barcode"></i>
                          <span className="nav-link-text"> RASTREAR </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/purchase`)}>
                        <NavLink to="/admin/purchase" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-boxes"></i>
                          <span className="nav-link-text"> COMPRAS </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/support`)}>
                        <NavLink to="/admin/support" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-headset"></i>
                          <span className="nav-link-text"> SOPORTE </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/profile`)}>
                        <NavLink to="/admin/profile" tag={Link}>
                          <i style={{marginRight: "10px"}} className="fas fa-user" />
                          <span className="nav-link-text">PERFIL</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeRoute(`/admin/user/address`)}>
                        <NavLink to="/admin/user/address" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-map-marked-alt"></i>
                          <span className="nav-link-text"> DIRECCIONES </span>
                        </NavLink>
                      </NavItem>
 
                      <NavItem className={activeRoute(`/admin/agency`)}>
                        <NavLink to="/admin/agency" tag={Link}>
                          <i style={{marginRight: "5px"}} className="fas fa-truck-loading"></i>
                          <span className="nav-link-text"> { String(agency.name).toLocaleUpperCase() } </span>
                        </NavLink>
                      </NavItem>

                      <NavItem style={{cursor: 'pointer'}}>
                        <NavLink
                          onClick={() => signOut()}>
                          <i style={{marginRight: "5px"}} className="fas fa-sign-out-alt" />
                          <span className="nav-link-text">CERRAR SESIÓN</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            </>
          }

          <div className="main-content" ref={mainContentRef}>
            {
              (admin === 1) ? <>
                <AdminNavbar
                  name={name}
                  picture={picture}
                  toggleSidenav={toggleSidenav}
                  sidenavOpen={sidenavOpen}
                  brandText={getBrandText(location.pathname)}
                />
              </> : <></>
            }

            <Switch>
              {getRoutes(routes)}
              {/* 
                <Route component={NotFound} />
                <Redirect from="*" to="/admin/" /> 
              */}
            </Switch>
            <AdminFooter />

          </div>

          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}
        </>
      }
    </>
  );
}

export default Admin;
