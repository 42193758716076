import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

const agencyName = "agp";

//AGENCY
export async function getInfoData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/info`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//SERVICES-PACKAGES
export async function getAllPackagesData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(CONS.services, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getPackageByCodeData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.services}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getOfficeAddressData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.services}/office`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCouponData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/coupon`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createPackageServicesData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/checkout`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createPackageServicesDomesticData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/checkdomestic`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}


export async function createPackageData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(CONS.services, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createPackageDomesticData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/domestic`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
    return err;
  }
}

export async function createPackageWarehouseDomesticData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/warehouse`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
    return err;
  }
}

//CALCULATOR
export async function makeRateData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(CONS.calculator, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function makeRateDataDomestic(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.calculator}/domestic`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//SUPPORT
export async function setSupportData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(CONS.support, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getSupportData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(CONS.support, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err
  }
}

//PLACES
export async function getAllStateData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.places}/states`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCityByStateData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.places}/state/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//PROFILE
export async function getUserInfoData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(CONS.profile, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateInfoData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(CONS.profile, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setPictureInfo(file) {
  const token = getToken('vboxToken');
	const formData = new FormData();
  formData.append('file', file.file)

  try {
    const res = await axios.post(`${CONS.profile}/picture`, formData, {
      headers: {
        'token': token,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getAllReceiveAddressData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.profile}/receive/address`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createReceiveAddressData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.profile}/receive/address`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateReceiveAddressData(jsn, id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.profile}/receive/address/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}
 
export async function deleteReceiveAddressData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.delete(`${CONS.profile}/receive/address/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getAllSenderAddressData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.profile}/sender/address`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createSenderAddressData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.profile}/sender/address`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateSenderAddressData(jsn, id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.profile}/sender/address/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteSenderAddressData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.delete(`${CONS.profile}/sender/address/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updatePasswordData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.profile}/password`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateDebtPackageData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.purchase}/debt`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function makeRateDataWarehouseDomestic(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.calculator}/warehouse/domestic`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function makeRateDataWarehouseDomesticOpen(jsn) {
  try {
    const res = await axios.post(`${CONS.calculator}/warehouse/domestic/open`, 
    jsn,
    {
      headers: {
        'agency': agencyName
      }
    }
  );
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createPackageServicesWarehouseDomesticData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.services}/checkwarehouse`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function createPackageWarehousePickupData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.purchase}/checkout/pickup`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function debtExistingData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.profile}/debt`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketsData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/info`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketByIdData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/user`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketByIdD(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/data/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketByIdDetailData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getSupportAdminData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.support}/admin`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}