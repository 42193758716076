/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getStateByIdData, updateStateData, deleteStateData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function EditState() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [active, setActive] = useState('0');
  const [loading, setLoading] = useState({ page: true, btn: false });
  const [modal, setModal] = useState(false);
  
  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    const res = await getStateByIdData(id);

    if(res.success === true) {
      setName(res.data.name);
      setCode(res.data.code);
      setActive(res.data.active);
    }

    setLoading({
      page: false,
      btn: false
    });
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const updateData = async () => {
    setLoading({page: false, btn: true });
    
    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre del estado");
    }
    else {
      const jsn = {
        "id": id,
        "name": name,
        "code": code,
        "active": active,
      }

      const res = await updateStateData(jsn);

      if(res.success === true) {
        getState();
        notifyAlert("success", "Éxito", "El estado se ha actualizado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizado el estado");
      }
    }

    setLoading({page: false, btn: false });
  }

  const deleteData = async () => {
    setLoading({page: false, btn: true });

    const res = await deleteStateData(id);

    if(res.success === true) {
      notifyAlert("success", "Éxito", "El estado se ha eliminado correctamente");
      window.location.href = "/admin/address/state";
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar el estado");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Estado </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading.page) ? <div className='text-center'>
                      <Spinner style={{
                        color: getColor()
                      }}/> 
                    </div> : 
                      <>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Nombre </label>
                              <Input
                                value={name}
                                className="form-control"
                                onChange={val => setName(val.target.value)}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Codigo </label>
                              <Input
                                value={code}
                                className="form-control"
                                onChange={val => setCode(val.target.value)}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Activo </label>
                              <select
                                style={{
                                  appearance: 'auto'
                                }}
                                className="form-control"
                                value={active}
                                onChange={val => setActive(val.target.value)}
                              >
                                <option value="1"> Si </option>
                                <option value="0"> No </option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12" >
                            <br />
                            <div className="float-right">
                              {
                                (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                                <div>
                                  <Button color="gray" to="/admin/address/state" tag={Link}> Regresar </Button>
                                  <Button
                                    color='danger'
                                    onClick={() => setModal(!modal)}
                                    > Eliminar
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: getColor(),
                                      color: '#fff',
                                      border: 0
                                    }}
                                    onClick={() => updateData()}
                                    > Guardar
                                  </Button>
                                </div>
                              } 
                            </div>
                          </Col>
                        </Row>
                      </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>


        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar el estado?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteData()}> Eliminar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}
