/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../components/Headers/Header";
import moment from "moment";
import upsHolidays from "../../utils/upsHolidays";
import momentBusiness from "moment-business-days";
import { extendMoment } from "moment-range";
import { getSenderContactData, createPickupWarehouseData } from "../../api/purchase";
import NotificationAlert from "react-notification-alert";

momentBusiness.updateLocale("us", {
  holidays: upsHolidays,
  holidayFormat: "MM-DD-YYYY",
});

const hoursBlock = [
  { value: "0800-1200", text: "8:00 a.m - 12:00 p.m" },
  { value: "0900-1300", text: "9:00 a.m - 01:00 p.m" },
  { value: "1100-1500", text: "11:00 a.m - 03:00 p.m" },
  { value: "1300-1700", text: "01:00 p.m - 05:00 p.m" },
  { value: "1500-1900", text: "03:00 p.m - 07:00 p.m" },
];

const packageQty = [
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
];
const numberOfDays = 8;

export default function PickupCreation(){
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [arrDates, setArrDates] = useState([]);

  const [date, setDate] = useState('');
  const [hour, setHour] = useState({
    startTime: '',
    endTime: '',
  });
  const [qty, setQty] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setValidDate = () => {
      moment.locale("es");
  
      const _arrDateNoWord = [];
      const _arrDate = [];
  
      const initialDate = momentBusiness().nextBusinessDay();
      const maxDate = moment(initialDate).add(numberOfDays - 1, "days");
      const businessDays = getBussinesDaysRange(initialDate, maxDate);
      const firtsBusinessDay = businessDays[0];
      const lastBusinessDay = businessDays[businessDays.length - 1];
  
      const allDate = getNotBusinessDaysInRange(
        firtsBusinessDay,
        lastBusinessDay
      );
  
      for (var val in allDate) {
        var d = allDate[val]._d;
        _arrDateNoWord.push(moment(d).format("YYYY-MM-DD"));
      }
  
      for (var valDate in businessDays) {
        var _date = businessDays[valDate]._d;
  
        var arrContains =
          _arrDateNoWord.indexOf(moment(_date).format("YYYY-MM-DD")) > -1;
  
        if (arrContains === false) {
          _arrDate.push({
            value: moment(_date).format("YYYY-MM-DD"),
            text: toTitleCase(moment(_date).format("dddd, MMMM D, YYYY")),
          });
        }
      }

      setArrDates(_arrDate);
    }

    const getSenderContact = async () => {
      const res = await getSenderContactData(id);

      if(res.success === true) {
        setName(res.data.name);
        setPhone(res.data.phone);
      }
    }

    getSenderContact();
    setValidDate();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getNotBusinessDaysInRange = (from, to) => {
    const days = getDateRange(from, to);
    const notBusinessDay = days.filter(
      (m) => !momentBusiness(m, "MM-DD-YYYY").isBusinessDay()
    );
    return notBusinessDay;
  }

  const getBussinesDaysRange = (from, to) => {
    const notBusinessDays = getNotBusinessDaysInRange(from, to);
    const days = getDateRange(from, to);
    for (let i = 0; i < notBusinessDays.length; i++) {
      days.push(momentBusiness(days[days.length - 1]).nextBusinessDay());
    }
    return days;
  }

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const getDateRange = (from, to, format = "MM-DD-YYYY") => {
    const momentRange = extendMoment(moment);
    const range = momentRange.range(from, to);
    const days = Array.from(range.by("days"));

    return days;
  }

  const handleChangeHour = (value) => {
    const hour = value;

    setHour({
      startTime: hour.split("-")[0].trim(),
      endTime: hour.split("-")[1].trim(),
    });
  }

  const pickupCreation = async () => {
    setLoading(true);

    if(date === "") {
      notifyAlert("danger", "Error", "La fecha no puede estar vacía");
    }
    else if(hour.startTime === "" || hour.endTime === "") {
      notifyAlert("danger", "Error", "La hora no puede estar vacía");
    }
    else if(qty === "") {
      notifyAlert("danger", "Error", "La cantidad no puede estar vacía");
    }
    else if(name === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(phone === "") {
      notifyAlert("danger", "Error", "El teléfono no puede estar vacío");
    }
    else {
      const jsn = {
        "id": id,
        "date": date,
        "startTime": hour.startTime,
        "endTime": hour.endTime,
        "qty": qty,
        "name": name,
        "phone": phone
      }
      
      const res = await createPickupWarehouseData(jsn);

      if(res.success === true) {
        setDate('');
        setHour({
          startTime: '',
          endTime: '',
        });
        setQty('');

        window.location.href = res.data;
      }
      else {
        notifyAlert("danger", "Error", res.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Programar Recogida </h3>
                  </div>
                  <div className="col mb-0">
                    <div className='float-right'>
                      <h3> Tarifa de Pickup: $15.00</h3>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Fecha de recogida </label>
                        <select 
                          className="form-control"
                          onChange={(val) => setDate(val.target.value)}>
                          <option value=""> Seleccionar </option>
                        {
                          arrDates.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={item.value}> {item.text} </option>
                            )
                          })
                        }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Hora der recogida </label>
                        <select 
                          className="form-control"
                          onChange={(val) => handleChangeHour(val.target.value)}>
                          <option value=""> Seleccionar </option>
                        {
                          hoursBlock.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={item.value}> {item.text} </option>
                            )
                          })
                        }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Cantidad de paquetes </label>
                        <select 
                          className="form-control"
                          onChange={(val) => setQty(val.target.value)}>
                        <option value=""> Seleccionar </option>
                        {
                          packageQty.map((item, key) => {
                            return (
                              <option 
                                key={key} 
                                value={item.value}> {item.text} </option>
                            )
                          })
                        }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <p style={{
                        fontSize: '13px',
                        color: '#525f7f',
                      }}> 
                        Es muy importante que estés pendiente de la visita de UPS en el horario seleccionado, 
                        si consideras seguro dejarle el paquete desatendido a UPS puedes hacerlo. 
                        Este será el único intento de recogida. 
                        Ademas puedes indicar todos los paquetes que enviarás en este horario. 
                        No será necesario que programes otras recogidas.
                      </p>
                    </Col>
                  </Row>

                  <Row style={{
                    marginTop: '10px'
                  }}>
                    <Col lg="12">
                      <h2>Persona de Contacto</h2>
                    </Col>

                    <Col lg="8">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          className="form-control"
                          placeholder="Nombre"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Teléfono </label>
                        <Input
                          className="form-control"
                          placeholder="Teléfono"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <p style={{
                        fontSize: '13px',
                        color: '#525f7f',
                      }}> 
                        UPS podrá contactar a esta persona ante cualquier dificultad con la recogida. 
                      </p>
                    </Col>

                    <Col lg="12">
                      <h2> Nota </h2>
                    </Col>
                    <Col lg="12">
                      <p style={{
                        fontSize: '13px',
                        color: '#525f7f',
                      }}> Al realizar su pago por la recogida, la misma no podra ser cancelada. </p>
                    </Col>
                  </Row>


                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/purchase" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => pickupCreation()}
                              > Solicitar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

