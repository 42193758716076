/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import Compressor from 'compressorjs';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getProductByIdData, updateProductData, deleteProductData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function EditProduct() {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [sku, setSKU] = useState("");
  const [description, setDescription] = useState("");
  const [typeProduct, setTypeProduct] = useState("");
  const [interName, setInterName] = useState("");
  const [active, setActive] = useState('0');
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState({page: true, btn: false});
  const [error, setError] = useState(false);

  useEffect( ()=> {
    getProductById();
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const getProductById = async () => {
    const res = await getProductByIdData(id);

    if(res.success === true) {
      setError(false);
      const _picture = (res.data.picture === null) ? '' : res.data.picture;
      setPicture(_picture);
      setName(res.data.name);
      setSKU(res.data.sku);
      setDescription(res.data.description);
      setTypeProduct(res.data.type);
      setInterName(res.data.interName);
      setActive(res.data.active);
    }
    else {
      setError(true);
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos de la marca");
    }
    setLoading(false);
  }

  const updateProduct = async () => {
    setLoading({...loading, btn: true});
    
    let fileCompress = null;
    
    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(String(sku).trim() === "") {
      notifyAlert("danger", "Error", "El SKU no puede estar vacío");
    }
    else if(String(description).trim() === "") {
      notifyAlert("danger", "Error", "La descripción no puede estar vacía");
    }
    else if(String(interName).trim() === "") {
      notifyAlert("danger", "Error", "El nombre interno no puede estar vacío");
    }
    else if(String(typeProduct).trim() === "") {
      notifyAlert("danger", "Error", "El tipo de producto no puede estar vacío");
    }
    else {
      fileCompress = (file && Object.keys(file).length === 0 && file.constructor === Object) ? null : await renderImageToSend(file);
   
      const res = await updateProductData(id, name, sku, description, interName, active, typeProduct, fileCompress);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "Se ha actualizado el producto exitosamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar el producto");
      }

    }
    setLoading({...loading, btn: false});
  }

  const deleteProduct = async () =>  {
    const res = await deleteProductData(id);

    if(res.success === true) {
      window.location.href = '/admin/products'
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar el producto");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Producto </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
              {
                (loading.page) ?
                <div className="text-center">
                  <Spinner style={{color: '#004899'}} />
                </div>:
                (error) ? 
                <div>
                  <div colSpan={10} className='text-muted text-center'>
                    <label> Se ha presentado un error, intente de nuevo mas tarde. </label>
                    <br />
                    <Button color="gray" to="/admin/products" tag={Link}> Regresar </Button>
                  </div>
                </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label className="form-control-label"> Imagen </label>
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '150px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '148px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar"
                            src={picture} />
                      </div>

                      <div className="float-right">
                        <label className="custom-file-upload">
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                          Cargar
                        </label>
                      </div>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> SKU </label>
                        <Input
                          value={sku}
                          className="form-control"
                          onChange={val => setSKU(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Descripción </label>
                        <Input
                          value={description}
                          className="form-control"
                          onChange={val => setDescription(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Tipo de Producto </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={typeProduct}
                          onChange={val => setTypeProduct(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          <option value="GROUND"> Domestico </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre interno </label>
                        <Input
                          value={interName}
                          className="form-control"
                          onChange={val => setInterName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/products" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateProduct()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar el producto?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal()}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteProduct()}> Eliminar </Button>
          </ModalFooter>
        </Modal>

      </Container>

    </>
  )
}

