import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

//PRODUCTS
export async function getProductData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(CONS.product, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewProductData(name, sku, description, interName, active, typeProduct, comImg) {
	const token = getToken('vboxToken');
	
  const formData = new FormData();
  formData.append('name', name);
  formData.append('sku', sku);
  formData.append('description', description);
  formData.append('interName', interName);
  formData.append('active', active);
  formData.append('typeProduct', typeProduct);
  (comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.post(CONS.product, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getProductByIdData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.product}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateProductData(id, name, sku, description, interName, active, typeProduct, comImg) { 
  const token = getToken('vboxToken');
    
  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', name);
  formData.append('sku', sku);
  formData.append('description', description);
  formData.append('interName', interName);
  formData.append('active', active);
  formData.append('typeProduct', typeProduct);
  (comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);

  try {
    const res = await axios.put(CONS.product, formData, {
      headers: {
        'token': token,
        'Content-type': 'multipart/form-data'
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteProductData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.delete(`${CONS.product}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//COUPON
export async function getCouponData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(CONS.coupon, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewCouponData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(CONS.coupon, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCouponByIdData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.coupon}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateCouponData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(CONS.coupon, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteCouponData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.delete(`${CONS.coupon}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//ADDRESS - STATE
export async function getStateData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.places}/address/states`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewStateData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(`${CONS.places}/address/states`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getStateByIdData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.places}/address/states/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateStateData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.put(`${CONS.places}/address/states`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteStateData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.delete(`${CONS.places}/address/states/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//ADDRESS - CITY
export async function getCityData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.places}/address/city`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewCityData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(`${CONS.places}/address/city`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCityByIdData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.places}/address/city/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateCityData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.put(`${CONS.places}/address/city`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteCityData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.delete(`${CONS.places}/address/city/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err
  }
}

/* ORDER */
export async function processNextStatusData(tracking) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.order}/process`, {"tracking": tracking},{
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function getOrderData(row, page) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/page/${row}/${page}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getDomesticOrderData(row, page) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/domestic/${row}/${page}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getDataByIdentifierData(identifier) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/${identifier}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getPackageByIdentifierData(identifier) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/id/${identifier}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getPackageByIdentifierWarehouseData(identifier) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/warehouse/${identifier}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function addDimensionToPackageData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(`${CONS.order}/warehouse`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setStatusPackageData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.put(`${CONS.order}/status`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err
  }
}

//TRANSPORTS
export async function getTransportsData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(CONS.transport, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTransportTypeData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.transport}/type`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewTransportData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(CONS.transport, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function getTransportByIdData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.transport}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function editTransportData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.put(CONS.transport, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function deleteTransportData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.delete(`${CONS.transport}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

/* LOTS */
export async function getLotsData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(CONS.lots, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getLotByTypeData(type) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.lots}/type/${type}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewLotData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(CONS.lots, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getLotByIdData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.lots}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function getLotPackagesByIdData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.lots}/${id}/packages`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function getLotStatusData() {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.lots}/status`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function deleteLotPackageData(value) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.delete(`${CONS.lots}/package/${value}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function updateLotData(jsn) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(CONS.lots, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function downloadLabelData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.lots}/repackage/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

export async function downloadRoutePdfData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.lots}/route/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}

/* CLIENTS */
export async function getClientsData() {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(CONS.clients, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientByIdData(id) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.clients}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllClientInvoiceData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.clients}/invoices/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    console.log(err);
  }
}


/* USERS */
export async function getUsersData() {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.admin}/users`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getUsersByIdData(id) {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.admin}/users/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewUsersData(jsn) {
	const token = getToken('vboxToken');
	
	try {
		const res = await axios.post(`${CONS.admin}/users`, jsn, {
      headers: {
        'token': token,
	    }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateUsersData(jsn) {
	const token = getToken('vboxToken');
	
	try {
		const res = await axios.put(`${CONS.admin}/users`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewPasswordData(id) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.put(`${CONS.admin}/users/password`, {
      id: id
    }, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteUsersData(id) {
	const token = getToken('vboxToken');

	try {
		const res = await axios.delete(`${CONS.admin}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

/* SETTING */
export async function getAllSetttingData() {
  const token = getToken('vboxToken');

  try {
    const res = await axios.get(`${CONS.admin}/setting`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setAllSettingData(data) {
  const token = getToken('vboxToken');

  try {
    const res = await axios.post(`${CONS.admin}/setting`, data, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setCompanyPicture(file, type) {
  const token = getToken('vboxToken');
	const formData = new FormData();
  formData.append('file', file.file)
  formData.append('type', type)

  try {
    const res = await axios.post(`${CONS.admin}/picture`, formData, {
      headers: {
        'token': token,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getOrderStatusData(row, page, status) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.order}/packages/${row}/${page}/${status}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setSupportData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(`${CONS.support}/admin`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketByIdAdmin(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.support}/admin/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getTicketByIdDetailAdminData(id) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.get(`${CONS.support}/admin/detail/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function closeTicketData(jsn) {
  const token = getToken('vboxToken');
  
  try {
    const res = await axios.post(`${CONS.support}/admin/close`, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}