import jwt_decode from "jwt-decode";

export function getToken(index) {
	return window.localStorage.getItem(index);
}

export function setToken(index,value) {
	window.localStorage.setItem(index,value);
}

export function deleteToken(index){
	window.localStorage.removeItem(index);	
}

export function getColor() {
  const customizeData = JSON.parse(localStorage.getItem("agency"));
	return customizeData.color;
}

/**
 * 
 * @param {*} token Key token name
 * @param {*} value Value to return from token
 * @returns 
 */
export function getTokenData(token, value) {
	const _token = jwt_decode(window.localStorage.getItem(token));
	return _token[value];
}

export function getAgencyData(value) {
  return JSON.parse(window.localStorage.getItem("agency"))[value];
}