/* eslint-disable no-useless-escape */
import React from 'react';
import { Card, Container, CardHeader, Row, Col, CardBody } from "reactstrap";
import Header from "../../../components/Headers/Header";
import BarPackage from './components/BarPackage';
import BarPackageByDate from './components/BarPackageByDate';
import BarTotalPackage from './components/BarTotalPackage';
import ProfitValue from './components/ProfitValue';
import GrossValue from './components/GrossValue';

export default function Graph() {

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Graficas </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className="pl-lg-4">
                  <BarPackage />
                  <br /><br />
                  <GrossValue />
                  <br /><br />
                  <ProfitValue />
                  <br /><br />
                  <BarTotalPackage />
                  <br /><br />
                  <BarPackageByDate />
                </div>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>

    </>
  )
}
