/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from "../../components/Headers/Header";
import { Card, CardBody, Container, Row } from "reactstrap";
import { getAgencyData } from "../../utils/token";

export default function DebtCancel () {
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <div className="text-center">
                  <ul style={{
                    listStyle: "none",
                    padding: "0px",
                    marginTop: "20px"
                  }}>
                    <li>
                      <img 
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "contain",
                        }}
                        alt="..."
                        src={getAgencyData("logo")} />
                    </li>
                    <li style={{
                      marginTop: "40px",
                    }}>
                      <h1> Su pago no ha sido procesado, por favor intente nuevamente.</h1>
                    </li>
                    <li>
                      <p style={{
                        fontSize: "20px",
                      }}> 
                        Si este mensaje es mostrado por error, por favor contacte a soporte tecnico.
                        <br />
                        Si desea volver a intentarlo, por favor haga click en el siguiente boton.
                      </p>

                      <a href="/admin/purchase">
                        <button className="btn" style={{
                          backgroundColor: getAgencyData("color"),
                          color: "white",
                        }}>
                          Volver a intentar
                        </button>
                      </a>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
