import React, { useState } from 'react';
import Header from "../../components/Headers/Header";
import { Card, CardHeader, CardBody, Container, Row, Col,  Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import AddressUS from './../Profile/components/AddressUS';

export default function UserAddress() {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
 
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Direcciones </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '1') ? 'active' : ''}
                        onClick={() => toggle('1')}
                      >
                        Dirección
                        <img 
                          alt='ve'
                          style={{
                            width: '20px',
                            marginLeft: '5px',
                            marginTop: '-3px'
                          }}
                          src={require('./../../assets/img/usa.png').default}/>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <AddressUS />
                    </TabPane>
                  </TabContent>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
