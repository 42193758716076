/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getStateData, getCityByIdData, updateCityData, deleteCityData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function NewCity() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({
    page: true,
    btn: false,
  });
  const [arrState, setArrState] = useState([]);
  const [state, setState] = useState('');
  const [name, setName] = useState('');
  const [cost, setCost] = useState({air: 1, sea: 1});
  const [eta, setETA] = useState({air: 1, sea: 1});
  const [delivery, setDelivery] = useState({air: 1, sea: 1});
  const [active, setActive] = useState('0');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    func();
  }, []);
  
  const func = async () => {
    await getStates();
    getCityById();
  }
  
  const getStates = async () => {
    const res = await getStateData();
    if(res.success === true) {
      setArrState(res.data);
    }
  }
  
  const getCityById = async () => {
    const res = await getCityByIdData(id);
    if(res.success === true) {
      setState(res.data.state_id);
      setName(res.data.city);
      setCost({
        air: res.data.air_cost, 
        sea: res.data.ocean_cost
      });
      setETA({
        air: res.data.air_eta, 
        sea: res.data.ocean_eta
      });
      setDelivery({
        air: res.data.air_delivery, 
        sea: res.data.ocean_delivery
      });
      setActive(res.data.active);
    }

    setLoading({...loading, page: false });
  }
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const editNewData = async () => {
    setLoading({...loading, btn: true });
    
    if(String(state).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el estado");
    }
    else if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre de la ciudad");
    }
    else if(String(cost.sea).trim() === "" || Number(cost.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo de envío por mar");
    }
    else if(String(eta.sea).trim() === "" || Number(eta.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el ETA de envío por mar");
    }
    else if(String(delivery.sea).trim() === "" || Number(delivery.sea <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo del delivery por mar");
    }
    else if(String(cost.air).trim() === "" || Number(cost.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo de envío por aire");
    }
    else if(String(eta.air).trim() === "" || Number(eta.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el ETA de envío por aire");
    }
    else if(String(delivery.air).trim() === "" || Number(delivery.air <= 0)) {
      notifyAlert("danger", "Error", "Debe ingresar el costo del delivery por aire");
    }
    else {
      const jsn = {
        "id": id,
        "name": name,
        "state": state,
        "cost": cost,
        "eta": eta,
        "delivery": delivery,
        "active": active,
      }

      const res = await updateCityData(jsn);

      if(res.success === true) {
        getCityById();
        notifyAlert("success", "Éxito", "La ciudad se ha editado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al editar la ciudad");
      }
    }

    setLoading({...loading, btn: false });

  }

  const deleteData = async () => {
    const res = await deleteCityData(id);

    if(res.success === true) {
      notifyAlert("success", "Éxito", "La ciudad se ha eliminado correctamente");
      window.location.href = "/admin/address/city";
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar la ciudad");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Ciudad </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading.page === true) ? <div className='text-center'>
                      <Spinner 
                        style={{
                          color: getColor()
                        }} />
                    </div> : <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Estado</label>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={state}
                              onChange={val => setState(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              {
                                arrState.map((item, key) => {
                                  return (
                                    <option value={item.id} key={key}> {item.state} </option>
                                  )
                                })
                              }
                            </select>
                          </FormGroup>
                        </Col>
                              
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Nombre </label>
                            <Input
                              value={name}
                              className="form-control"
                              onChange={val => setName(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Costo Maritimo </label>
                            <Input
                              type='number'
                              min={1}
                              pattern='[0-9]+([,\.][0-9]+)?'
                              value={cost.sea}
                              className="form-control"
                              onChange={val => setCost({...cost, sea: val.target.value})}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> ETA Maritimo </label>
                            <Input
                              value={eta.sea}
                              className="form-control"
                              onChange={val => setETA({...eta, sea: val.target.value})}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Costo del Delivery Maritimo </label>
                            <Input
                              value={delivery.sea}
                              className="form-control"
                              onChange={val => setDelivery({...delivery, sea: val.target.value})}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Costo Aereo </label>
                            <Input
                              value={cost.air}
                              className="form-control"
                              onChange={val => setCost({...cost, air: val.target.value})}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> ETA Aereo </label>
                            <Input
                              value={eta.air}
                              className="form-control"
                              onChange={val => setETA({...eta, air: val.target.value})}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Costo del Delivery Aereo </label>
                            <Input
                              value={delivery.air}
                              className="form-control"
                              onChange={val => setDelivery({...delivery, air: val.target.value})}/>
                          </FormGroup>
                        </Col>


                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Activo </label>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={active}
                              onChange={val => setActive(val.target.value)}
                            >
                              <option value="1"> Si </option>
                              <option value="0"> No </option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                              <div>
                                <Button color="gray" to="/admin/address/city" tag={Link}> Regresar </Button>
                                <Button
                                  color='danger'
                                  onClick={() => setModal(!modal)}
                                  > Eliminar
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor: getColor(),
                                    color: '#fff',
                                    border: 0
                                  }}
                                  onClick={() => editNewData()}
                                  > Guardar
                                </Button>
                              </div>
                            } 
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar la ciudad?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteData()}> Eliminar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}
