import moment from "moment";

const newYears = moment().month(0).date(1).format('MM-DD-YYYY');
const memorialDay = getNthMondayOfMonth(4, 4).format('MM-DD-YYYY');
const independenceDay = moment().month(6).date(4).format('MM-DD-YYYY');
const laborDay = getNthMondayOfMonth(8, 1).format('MM-DD-YYYY');
const thanksgivingDay = getGivenDateOfMonth(moment().month(10).startOf('month'), 4, 4).format('MM-DD-YYYY');
const christmasDay = moment().month(11).date(25).format('MM-DD-YYYY');

function getNthMondayOfMonth(month = 0, position = 0) {
  var startOfMonth = moment().month(month).utc().startOf('month').startOf('isoweek');
  var studyDate = moment().month(month).utc().startOf('month').startOf('isoweek').add(position, 'w');

  if (studyDate.month() === startOfMonth.month()) {
    studyDate = studyDate.subtract(1, 'w');
  }
  return studyDate;
}

function getGivenDateOfMonth (startDate, dayOfWeek, weekNumber) {
  // Start of the month of the given startDate
  var myMonth = moment(startDate).startOf('month');
  // dayOfWeek of the first week of the month
  var firstDayOfWeek = myMonth.clone().isoWeekday(dayOfWeek);
  // Check if first firstDayOfWeek is in the given month
  if( firstDayOfWeek.month() !== myMonth.month() ){
      weekNumber++;
  }
  // Return result
  return firstDayOfWeek.add(weekNumber-1, 'weeks');
} 

const holidays = [
  newYears, 
  memorialDay, 
  independenceDay, 
  laborDay, 
  thanksgivingDay, 
  christmasDay
];

export default holidays;
