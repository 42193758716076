/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {Spinner, Row, Button, Input, Col, FormGroup, Container, Card, CardHeader, CardBody } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { makeRateDataWarehouseDomestic } from "../../../api/users";
import { getColor, getAgencyData } from 'utils/token';
import Header from "components/Headers/Header.js";

export default function AdminCalculator() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [zipCodeOrigin, setZipCodeOrigin] = useState('');
  //const [zipCodeDestination, setZipCodeDestination] = useState('');
  const [consignationType, setConsignationType] = useState('');
  const [weight, setWeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [data, setData] = useState({});

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const validInput = (inp) => {
    switch (inp) {
      case "weight":
        if (parseFloat(weight) > 150) {
          setWeight("150");
        }
        break;

      case "length":
        if (parseFloat(length) > 108) {
          setLength("108");
        }
        break;

      case "width":
        if (parseFloat(width) > 108) {
          setWidth("108");
        }
        break;

      case "height":
        if (parseFloat(height) > 108) {
          setHeight("108");
        }
        break;
      default:
        return false;
    }
  }

  const validData = () => {
    return (
      zipCodeOrigin !== "" &&
      //zipCodeDestination !== "" &&
      weight !== "" &&
      height !== "" &&
      length !== "" &&
      width !== "" &&
      parseFloat(weight) >= 1 &&
      parseFloat(height) >= 1 &&
      parseFloat(length) >= 1 &&
      parseFloat(width) >= 1
    );
  }

  const weightConverter = (val) => {
    return (parseFloat(val) / 2.2046).toFixed(2);
  }

  const makeRate = async () => {
    const total_pkg_dim = parseFloat(length) + 2 * ( parseFloat(width) + parseFloat(height) );

    setLoading(true);

    if(total_pkg_dim > 165) {
      notifyAlert("danger", "Mensaje", "Las dimensiones del paquete no pueden ser mayores a 165 pulgadas");
    }
    else {
      const jsn = {
        'origin_zipcode': zipCodeOrigin,
        'weight': weight,
        'length': length,
        'width': width,
        'height': height
      };

      const res = await makeRateDataWarehouseDomestic(jsn);
  
      if(res.success === true) {
        var total = (consignationType === "1") ? (Number(res.data.payment) + Number(res.data.ups_pickup)) : Number(res.data.payment);

        setData({
          "total": total.toFixed(2),
          "origin": zipCodeOrigin,
          "weight": weight,
          "length": length,
          "width": width,
          "height": height,
          "consignationType": consignationType,
          "picture": res.data.picture,
          "sku": res.data.sku
        });

        setError(false);
      }
      else {
        setError(true);
        notifyAlert("danger", "Mensaje", "Ha ocurrido un error al calcular su paquete");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Calculadora de envio </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div style={{ padding: "0px"}}>
                  <div className='paddingHorizontal'>
                    <Row>
                      <Col md="6">
                        <div style={{
                          border: '1px solid #d3d3d4',
                          borderRadius: '10px',
                          padding: '25px 25px 15px',
                          marginBottom: '20px'
                        }}>
                          <label style={{
                            fontSize: '1.28rem',
                            fontWeight: '600',
                            color: getColor(),
                          }}> Origen </label>

                          <FormGroup>
                            <label className="form-control-label">Código Postal</label>
                            <Input
                              placeholder="Codigo postal"
                              type="text"
                              value={zipCodeOrigin}
                              onChange={(e) => setZipCodeOrigin(e.target.value)}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                      
                      {/*
                        <Col md="4">
                          <div style={{
                            border: '1px solid #d3d3d4',
                            borderRadius: '10px',
                            padding: '25px 25px 15px',
                            marginBottom: '20px'
                          }}>
                            <label style={{
                              fontSize: '1.28rem',
                              fontWeight: '600',
                              color: getColor(),
                            }}> Destino </label>

                            <FormGroup>
                              <label className="form-control-label">Código Postal</label>
                              <Input
                                placeholder="Codigo postal"
                                type="text"
                                value={zipCodeDestination}
                                onChange={(e) => setZipCodeDestination(e.target.value)}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      */}

                      <Col md="6">
                        <div style={{
                          border: '1px solid #d3d3d4',
                          borderRadius: '10px',
                          padding: '25px 25px 15px',
                          marginBottom: '20px'
                        }}>
                          <label style={{
                            fontSize: '1.28rem',
                            fontWeight: '600',
                            color: getColor(),
                          }}> Tipo de envío </label>

                          <FormGroup>
                            <label className="form-control-label"> Seleccionar el tipo de consignación </label>
                            <select
                                id="label-select"
                                className="form-control"
                                value={consignationType}
                                onChange={(e) => setConsignationType(e.target.value)}
                              >
                                <option value=""> Seleccionar </option>
                                <option value="1"> Recogida por UPS </option>
                                <option value="0"> Entregar en oficina de UPS </option>
                              </select>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col md="12">
                        <div style={{
                          border: '1px solid #d3d3d4',
                          borderRadius: '10px',
                          padding: '25px 25px 15px',
                          marginBottom: '20px'
                        }}>
                          <label style={{
                            fontSize: '1.28rem',
                            fontWeight: '600',
                            color: getColor(),
                          }}> Peso y dimensiones </label>
                          <Row>
                            <Col md="3">
                              <FormGroup>
                                <label className="form-control-label">Peso (Libras)</label>
                                <Input
                                  type="number"
                                  min="1"
                                  max="150"
                                  value={weight}
                                  onChange={(e) => setWeight(e.target.value)}
                                  onKeyPress={validInput("weight")}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="3">
                              <FormGroup>
                                <label className="form-control-label"> Largo (Pulgadas) </label>
                                <Input
                                  type="number"
                                  min="1"
                                  max="150"
                                  value={length}
                                  onChange={(e) => setLength(e.target.value)}
                                  onKeyPress={validInput("length")}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="3">
                              <FormGroup>
                                <label className="form-control-label"> Ancho (Pulgadas) </label>
                                <Input
                                  type="number"
                                  min="1"
                                  max="150"
                                  value={width}
                                  onChange={(e) => setWidth(e.target.value)}
                                  onKeyPress={validInput("width")}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="3">
                              <FormGroup>
                                <label className="form-control-label"> Alto (Pulgadas) </label>
                                <Input
                                  type="number"
                                  min="1"
                                  max="150"
                                  value={height}
                                  onChange={(e) => setHeight(e.target.value)}
                                  onKeyPress={validInput("height")}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-center">
                        <div>
                          {loading ? (
                            <Spinner
                              style={{ color: getColor(), marginBottom: "24px" }}
                            />
                          ) : (
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: "#fff",
                                fontWeight: "600",
                                fontSize: "1.28rem",
                                padding: "10px 30px",
                                width: "150px",
                                cursor: "pointer"
                              }}
                              disabled={!validData()}
                              className={validData() ? "btnRate" : "btnDisable"}
                              onClick={() => makeRate()}
                            > Calcular 
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col md="12">
                        {
                          (error.data) ? (
                            <div className="errMsgServices">
                              <label> Ha ocurrido un error al obtener el calculo </label>
                            </div>
                          ) :
                          (data.length > 0) ?  
                            <>
                              <div 
                                style={{
                                  fontWeight: "bold",
                                  color: getColor(),
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}>
                                <h2 style={{
                                  fontSize: "2.3rem"
                                }}> Nuestros servicios {getAgencyData('name')} </h2>
                                <hr />
                              </div>

                              <div>
                                <Row>
                                  <Col md="4" className="align-self-center text-center">
                                    <img 
                                      alt="..." 
                                      style={{
                                        width: "50%",
                                        objectFit: "scale-down"
                                      }}
                                      src={data.picture} />

                                    <div className="dvTitle">
                                      <span> {data.name} </span>
                                    </div>
                                  </Col>

                                  <Col md="4" className="align-self-center">
                                    <ul style={{
                                      padding: "0",
                                      lineHeight: "3",
                                      fontSize: "15px",
                                      listStyle: "none"
                                    }}>
                                      <li>
                                        <b> Dimensiones: </b>
                                        <span>
                                          {" "}
                                          {length}"x{width}"x{height}"{" "}
                                        </span>
                                      </li>
                                      
                                      <li>
                                        <b> Peso: </b>
                                        <span>
                                          {" "}
                                          {weight}lbs ({weightConverter(weight)}Kg){" "}
                                        </span>
                                      </li>
                                      <li>
                                        <b> Servicio: </b>
                                          <span>
                                            Domestico
                                            <i style={{marginLeft: "7px", color: getColor()}} className='fas fa-truck' />
                                          </span>
                                      </li>
                                        <li>
                                        <b> Tipo de pickup: </b>
                                        {
                                        (consignationType === "1") ? 
                                          <span> Recogida por UPS </span> :
                                          <span> Entregar en oficina UPS</span>
                                        }
                                      </li>
                                    </ul>
                                  </Col>

                                  <Col md="4" className="align-self-center">
                                    <div>
                                      <ul style={{
                                        padding: "0",
                                        lineHeight: "3",
                                        fontSize: "15px",
                                        listStyle: "none"
                                      }}>
                                        <li style={{
                                          textTransform: "uppercase",
                                          fontSize: "18px"
                                        }}>
                                          <b>Precio del envío </b>
                                          <br />
                                          <span style={{
                                            color: getColor(), 
                                            fontWeight: "bold",
                                            fontSize: "2.3rem"
                                          }}> 
                                            ${data.total}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          : <></>
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
